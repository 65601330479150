import justClone from 'just-clone';

export abstract class SuperModel {
  id?: string;

  constructor(object: any = {}) {
    Object.assign(this, object);
  }

  abstract readonly serialised;

  get clone() {
    return justClone(this.serialised);
  }
}
