import { Injectable } from '@angular/core';
import { bitfFormatDateISO } from '@common/libs/bitforce/utils/bitf-dates.utils';
import { bitfToTranslate } from '@common/libs/bitforce/utils/bitf-translate.utils';
import { DietDay, MyMeal } from '@core/models';
import {
  DailyDiaryService,
  DialogsService,
  StoreService,
  ToastMessagesService,
  UsersService,
} from '@core/services';
import { EBitfCloseEventStatus, EBitfUiMessageType, ERecordState } from '@enums';
import { IBitfApiResponse, IBitfCloseEvent } from '@interfaces';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { CopyToDiaryDialogComponent } from './copy-to-diary-dialog/copy-to-diary-dialog.component';
import { CONSTANTS } from '@constants';

@Injectable({
  providedIn: 'root',
})
export class MyMealsService {
  constructor(
    private dialogsService: DialogsService,
    private storeService: StoreService,
    private dailyDiaryService: DailyDiaryService,
    private translateService: TranslateService,
    private toastMessagesService: ToastMessagesService,
    private usersService: UsersService
  ) {}

  addMealToDiary(myMealId: string, useSuggestedMealService = false): Observable<void> {
    return this.dialogsService.dialog
      .open(CopyToDiaryDialogComponent, {
        data: {
          selectedDate: this.storeService.store.selectedDate,
        },
        panelClass: 'dialog-no-padding',
        autoFocus: false,
      })
      .afterClosed()
      .pipe(
        filter((result: IBitfCloseEvent<any>) => result !== undefined),
        filter((result: IBitfCloseEvent<any>) => result.status === EBitfCloseEventStatus.OK),
        map((result: IBitfCloseEvent<any>) => result.data),
        switchMap((data: { date: Date; diaryTimeId: number }) =>
          this.getOrCreateDietDay(data).pipe(map(dietDay => ({ dietDay, diaryTimeId: data.diaryTimeId })))
        ),
        switchMap(({ dietDay, diaryTimeId }) => {
          const mealId = `diary-${dietDay.id}-${diaryTimeId}`;
          return this.dailyDiaryService.action<{}>({
            id: dietDay.id,
            path: `/meals('${mealId}')/${useSuggestedMealService ? 'add-suggested-meal' : 'add-my-meal'}`,
            modelMapper: 'action',
            isBodyRaw: true,
            body: {
              state: ERecordState.INSERTED,
              myMealId: useSuggestedMealService ? undefined : myMealId,
              suggestedMealId: useSuggestedMealService ? myMealId : undefined,
            },
          });
        }),
        switchMap(() => this.translateService.get(bitfToTranslate('FAVORITES.MY_MEALS.ADDED_TO_DIARY'))),
        tap((message: string) => {
          this.toastMessagesService.show({
            type: EBitfUiMessageType.SUCCESS,
            title: message,
          });
        }),
        map(() => {})
      );
  }

  private getOrCreateDietDay({ date }): Observable<DietDay> {
    return this.dailyDiaryService
      .get<DietDay>({
        filter: `date eq ${bitfFormatDateISO(date)} and userId eq ${this.storeService.store.user.id}`,
      })
      .pipe(
        switchMap((response: IBitfApiResponse<DietDay[]>) => {
          if (response.content && response.content[0]) {
            return of(response.content[0]);
          } else {
            return this.dailyDiaryService.createDietDay(date);
          }
        })
      );
  }

  deleteMeal(mealId: string): Observable<void> {
    return of(undefined).pipe(
      switchMap(() =>
        this.dialogsService.dialog
          .open(CONSTANTS.okCancelDialogComponent, {
            data: {
              title: bitfToTranslate('DELETE.DIALOG.TITLE'),
              message: bitfToTranslate('DELETE.DIALOG.TEXT'),
              okText: bitfToTranslate('COMMON.OK'),
              cancelText: bitfToTranslate('COMMON.CANCEL'),
            },
          })
          .afterClosed()
      ),
      filter(
        (dialogRes: IBitfCloseEvent<void>) => dialogRes && dialogRes.status === EBitfCloseEventStatus.OK
      ),
      switchMap(() =>
        this.usersService.delete({
          id: this.storeService.store.user.id,
          relation: 'myMeals',
          relationId: mealId,
        })
      ),
      map(() => {})
    );
  }
}
