import { Component, OnInit, ViewChild, ElementRef, OnDestroy, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { takeUntil, tap, debounce, distinctUntilChanged, map } from 'rxjs/operators';
import { Subject, timer } from 'rxjs';

@Component({
  selector: 'aboca-favorite-search',
  templateUrl: './favorite-search.component.html',
  styleUrls: ['./favorite-search.component.scss'],
})
export class FavoriteSearchComponent implements OnInit, OnDestroy {
  @ViewChild('searchInput', { static: true })
  searchInput: ElementRef<HTMLInputElement>;

  @Output()
  searchChange = new EventEmitter<string>();

  form: FormGroup;

  private unsubscribe$ = new Subject<void>();

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit() {
    this.form = this.formBuilder.group({
      search: [''],
    });

    this.form
      .get('search')
      .valueChanges.pipe(
        takeUntil(this.unsubscribe$),
        debounce(() => timer(500)),
        distinctUntilChanged(),
        map((value: string) => value.trim()),
        tap((searchWord: string) => this.searchChange.emit(searchWord))
      )
      .subscribe();
  }

  onSubmit(event: Event) {
    this.searchInput.nativeElement.blur();
    event.preventDefault();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
