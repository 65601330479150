import { Injectable } from '@angular/core';
import { EBitfSidenavMode, EBitfSidenavPosition } from '@enums';
import { IBitfCloseEvent } from '@interfaces';
import { map } from 'rxjs/operators';
import { DialogsService } from './dialogs.service';
import { StoreService } from '@services';
import { BitfMatSidenavService } from '@bitf/core/services/sidenav/material/bitf-mat-sidenav.service';

@Injectable({
  providedIn: 'root',
})
export class DialogOrSidebarService {
  constructor(
    private storeSevice: StoreService,
    private dialogsService: DialogsService,
    private bitfMatSidenavService: BitfMatSidenavService
  ) {}

  openDialogOrSidebar({ component, data, dialogOptions }) {
    dialogOptions = dialogOptions || {};
    const useDialog = this.storeSevice.store.activeBreakpoints.isMobile;
    let close: any;
    if (useDialog) {
      dialogOptions.data = data;
      const dialog = this.dialogsService.dialog.open(component, dialogOptions);
      close = dialog.afterClosed().pipe(map(this.createDialogOrSidnavResultMapper()));
    } else {
      close = this.bitfMatSidenavService
        .open({
          component: component,
          sidenavOptions: {
            mode: EBitfSidenavMode.OVER,
            position: EBitfSidenavPosition.END,
            autoFocus: false,
          },
          componentData: data,
        })
        .pipe(map(this.createDialogOrSidnavResultMapper()));
    }

    return close;
  }

  private createDialogOrSidnavResultMapper() {
    return (result: IBitfCloseEvent<any>) => {
      let data: any;
      if (result) {
        data = result.data;
      }
      return data;
    };
  }
}
