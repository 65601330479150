import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, OnInit } from '@angular/core';

import { Food, DiaryActivity, ERecordState, FoodGroup } from '@models';
import { StoreService } from '@services';
import { nullOrNaN } from '@bitf/utils/bitf-numbers.utils';

@Component({
  selector: 'aboca-item-row',
  templateUrl: './item-row.component.html',
  styleUrls: ['./item-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemRowComponent implements OnInit {
  @Input()
  item: DiaryActivity | Food | FoodGroup;
  @Input()
  textTruncate = false;
  @Input()
  editable = false;

  ERecordState = ERecordState;

  @Output()
  edit = new EventEmitter<void>();

  hasNutrients: boolean;
  kiloCalories: number;

  constructor(private storeService: StoreService) {}

  ngOnInit() {
    if (this.item instanceof Food) {
      this.hasNutrients = true;
      this.kiloCalories = this.item.kiloCalories;
    } else if (this.item instanceof DiaryActivity) {
      this.hasNutrients = false;
      this.kiloCalories = this.item.getKCalories(this.storeService.store.user.userData.weight);
      if (this.item.quantity == null) {
        this.editable = false;
      }
    } else if (this.item instanceof FoodGroup) {
      this.hasNutrients = false;
      this.kiloCalories = this.item.getTotalKCalories();
    }
  }

  nullOrNaN(value: number) {
    return nullOrNaN(value);
  }
}
