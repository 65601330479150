import { Directive, Input, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { MatBadge } from '@angular/material/badge';

import { StoreService } from '@services';
import { EUserDefaultRoutes, EStoreActions } from '@enums';
import { User, Store } from '@models';
import { IBitfStoreEvent } from '@interfaces';
import { filter, map, tap } from 'rxjs/operators';
import { noOp } from '@bitf/utils/bitf-function.utils';

@Directive({
  selector: '[abocaMenuItemBadge][matBadge]',
})
export class MenuItemBadgeDirective implements OnInit, OnDestroy {
  private static strategyMap = {
    [EUserDefaultRoutes.MY_PROGRESS]: MenuItemBadgeDirective.weightBadge,
  };

  // tslint:disable-next-line:no-input-rename
  @Input('abocaMenuItemBadge')
  route: EUserDefaultRoutes;

  private subscription: Subscription;

  private static weightBadge(host: MatBadge, user: User) {
    if (!user.hasUpdatedWeight) {
      host.hidden = false;
      host.content = '1';
    }
  }

  constructor(private host: MatBadge, private storeService: StoreService) {}

  ngOnInit() {
    this.subscription = this.storeService.store$
      .pipe(
        filter((storeEvent: IBitfStoreEvent<Store>) => storeEvent.action === EStoreActions.SET_USER),
        map((storeEvent: IBitfStoreEvent<Store>) => storeEvent.store.user)
      )
      .subscribe(user => {
        this.update(user);
      });
    this.update(this.storeService.store.user);
  }

  private update(user: User) {
    this.host.hidden = true;
    const handler = MenuItemBadgeDirective.strategyMap[this.route] || noOp;
    handler(this.host, user);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
