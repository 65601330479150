import { BITF_CONFIGS as COMMON_BITF_CONFIGS } from '@common/configs/common-bitf.configs';
import { EUserState, EUserDefaultRoutes } from '@web/enums';

const WEB_BITF_CONFIGS = {
  urls: { signInUrl: 'signin', signOutUrl: 'signin' },
  serviceWorker: { checkUpdateInterval: 30000 },
  getRouteFromUserState(state: EUserState = EUserState.None) {
    let route: string;
    switch (state) {
      case EUserState.None:
        route = EUserDefaultRoutes.SIGNIN;
        break;
      case EUserState.ProfileOnly:
        route = EUserDefaultRoutes.WIZARD;
        break;
      case EUserState.ProfileAndData:
        route = EUserDefaultRoutes.WEIGHT_TARGET;
        break;
      case EUserState.ProfileDataAndDiet:
        route = EUserDefaultRoutes.DASHBOARD;
        break;
      default:
        route = EUserDefaultRoutes.SIGNIN;
    }

    return route;
  },
};

export const BITF_CONFIGS = Object.assign(COMMON_BITF_CONFIGS, WEB_BITF_CONFIGS);
