<div class="d-flex flex-column h-100"
  [hidden]="state !== 'NORMAL'">
  <mat-toolbar class="position-relative flex-shrink-0 aboca-toolbar">
    <mat-toolbar-row>
      <aboca-header-close class="flex-grow-1"
        (close)="onClose()">
        <span class="text-capitalize">{{'COMMON.VERIFY' | translate}}</span> {{model.title | translate}}
      </aboca-header-close>
    </mat-toolbar-row>
  </mat-toolbar>

  <div class="flex-grow-1 aboca-scroll-y">
    <form [formGroup]="form">
      <div class="container-fluid">
        <aboca-centered-add-button [label]="addLabel"
          (add)="onAddItem()"></aboca-centered-add-button>
        <mat-checkbox formControlName="selectAll"
          class="w-100 border-bottom border-secondary mat-h2 mb-0 d-md-block">
          {{'MEAL_VERIFICATION.CONFIRM_ALL' | translate}}
        </mat-checkbox>
      </div>

      <mat-list>
        <ng-container *ngFor="let group of groups">
          <mat-list-item class="h-auto">
            <div class="w-100 px-1 mb-1">
              <aboca-food-group [foodGroup]="group.foodGroup">
                <mat-checkbox *ngFor="let food of group.foodGroup.foods;  let groupIndex = index;"
                  [formControl]="getSelectedControl().controls[group.shift + groupIndex]"
                  class="w-100">
                  <aboca-item-row [item]="food"
                    class="flex-grow-1 text-truncate"
                    [textTruncate]="true"
                    [editable]="isItemRowEditable(food)"
                    (edit)="onEdit(group.shift + groupIndex)"
                    [column]="true"></aboca-item-row>
                </mat-checkbox>

                <div mat-action-row>
                  <mat-checkbox [formControl]="group.selectAllControl">
                    {{'MEAL_VERIFICATION.SELECT' | translate}}
                  </mat-checkbox>
                </div>
              </aboca-food-group>
            </div>
          </mat-list-item>
          <div class="position-relative mx-3">
            <mat-divider></mat-divider>
          </div>
        </ng-container>

        <ng-container *ngFor="let record of model.nonGroupedRecords; let i = index;">
          <mat-list-item class="d-flex">
            <mat-checkbox [formControl]="getSelectedControl().controls[i + nonGroupedShift]"
              class="w-100">
              <aboca-item-row [item]="record"
                class="flex-grow-1 text-truncate"
                [textTruncate]="true"
                [editable]="isItemRowEditable(record)"
                (edit)="onEdit(i + nonGroupedShift)"
                [column]="true"></aboca-item-row>
            </mat-checkbox>
          </mat-list-item>
          <div class="position-relative mx-3">
            <mat-divider></mat-divider>
          </div>
        </ng-container>
      </mat-list>

      <div class="container-fluid mt-3">
        <div class="w-100 border-bottom border-secondary mat-h2">{{'MEAL_VERIFICATION.NOTE' | translate}}
        </div>
        <mat-form-field appearance="outline"
          class="w-100">
          <textarea matInput
            formControlName="notes"
            placeholder="{{'MEAL_VERIFICATION.ADD_A_NOTE' | translate}}"
            cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="2"
            cdkAutosizeMaxRows="3"></textarea>
        </mat-form-field>
      </div>
    </form>

    <div class="container-fluid mt-3"
      *ngIf="nutritionData">
      <aboca-food-nutrients-table [nutritionData]="nutritionData"></aboca-food-nutrients-table>
    </div>
  </div>

  <div class="p-3 d-flex border-top flex-shrink-0">
    <button mat-flat-button
      (click)="onConfirm()"
      color="primary"
      class="text-uppercase flex-grow-1">{{'DASHBOARD.CONFIRM' | translate}}</button>
  </div>
</div>

<aboca-search *ngIf="state === 'ADDING'"
  [fetchAll]="fetchAll"
  [usedAsComponent]="true"
  [title]="model.title"
  [searchService]="searchService"
  (added)="onItemAdded($event)"
  (closed)="onSearchClosed()"></aboca-search>

<aboca-food-edit *ngIf="state === 'EDIT'"
  [food]="model.records[this.selectedItemIndex]"
  [closeButton]="false"
  (back)="onEditBack()"
  (apply)="onEditApply($event)"
  (delete)="onSelectedDelete()"></aboca-food-edit>