import { UserData, EGender, EDiaryUse } from '@models';

export class WizardResultCalculator {
  constructor(private userData: UserData) {}

  isWaistCircumferenceUsed(): boolean {
    if (this.userData.diaryUse === EDiaryUse.ANNOTATIONS) {
      return false;
    }

    const imc = this.userData.imc;
    return imc >= 18.5 && imc < 25;
  }

  needsInDepthFeatures(): boolean {
    if (this.userData.diaryUse === EDiaryUse.ANNOTATIONS) {
      return false;
    }
    const imc = this.userData.imc;
    const between25And30 = imc >= 25 && imc < 30;
    let result = between25And30;
    if (this.isWaistCircumferenceUsed()) {
      const maxCircumference = this.userData.gender === EGender.MALE ? 102 : 88;
      result = this.userData.waistCircumference > maxCircumference;
    }

    return result;
  }

  get bmi() {
    return this.userData.weight / (this.userData.height / 100) ** 2;
  }

  getResult(): EWizardResult {
    let result: EWizardResult = EWizardResult.DO_NOT_LOOSE_WEIGHT;
    const imc = this.userData.imc;

    if (imc >= 30) {
      result = EWizardResult.LOOSE_WEIGHT;
    } else if (this.needsInDepthFeatures() && this.countNumberOfYes() >= 2) {
      result = EWizardResult.LOOSE_WEIGHT;
    } else if (imc >= 18.5) {
      result = EWizardResult.PREVENT_WEIGHT_GAIN;
    }

    return result;
  }

  private countNumberOfYes() {
    let counter = 0;
    if (this.userData.isSmoker) {
      counter++;
    }
    if (this.userData.hasBloodPressureExcess) {
      counter++;
    }
    if (this.userData.hasCholesterolLdlExcess) {
      counter++;
    }
    if (this.userData.hasCholesterolHdlExcess) {
      counter++;
    }
    if (this.userData.hasGlycemiaExcess) {
      counter++;
    }
    if (this.userData.hasFamilyDiseases) {
      counter++;
    }
    return counter;
  }
}

export enum EWizardResult {
  DO_NOT_LOOSE_WEIGHT = 0,
  LOOSE_WEIGHT = 3,
  PREVENT_WEIGHT_GAIN = 4,
}
