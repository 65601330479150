import { Component, Injector } from '@angular/core';

import { CommonMenuComponent } from '@common/shared/components/menu/common-menu.component';

import { APP_VERSION, BUILD_DATE } from '@env/version';

@Component({
  selector: 'aboca-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent extends CommonMenuComponent {
  appVersion = APP_VERSION;
  buildDate = BUILD_DATE;
  menuItems = [
    { label: 'Diario', url: '/dashboard', icon: 'home' },
    { label: 'Wizard', url: '/wizard', icon: 'home' },
    { label: 'Pianificazione', url: '/pianificazione', icon: 'home' },
    { label: 'Taratura', url: '/peso-obiettivo', icon: 'home' },
    { label: 'Scan test', url: '/info/scan-test', icon: 'home' },
  ];

  constructor(public injector: Injector) {
    super(injector);
  }
}
