import { Injectable } from '@angular/core';

import { IBitfUiRoleManagerService } from '@interfaces';

@Injectable({
  providedIn: 'root',
})
export class UiRoleManagerService implements IBitfUiRoleManagerService {
  canI(_: any): boolean {
    return true;
  }
}
