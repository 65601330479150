import { BitfStore } from '@bitf/core/models/bitf-store.model';
import { User } from '@models';

export class Store extends BitfStore {
  user: User;
  barcode: string;
  selectedDate = new Date();

  constructor() {
    super();
  }
}
