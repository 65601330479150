import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Optional, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BitfMatSidenavService } from '@bitf/core/services/sidenav/material/bitf-mat-sidenav.service';
import { bitfToTranslate } from '@bitf/utils/bitf-translate.utils';
import { SuggestedMealsService } from '@common/core/services/api/suggested-meals.service';
import { CONSTANTS } from '@constants';
import { EBitfCloseEventStatus, EBitfUiMessageType } from '@enums';
import { IBitfCloseEvent, IBitfJsonBatchRequest, ISearchResult } from '@interfaces';
import {
  CombinationTypeTranslationMap,
  ECombinationType,
  MyMeal,
  MyMealRecord,
  SuggestedMeal,
} from '@models';
import { TranslateService } from '@ngx-translate/core';
import {
  AbocaBatchService,
  DialogsService,
  FoodProductService,
  LoaderService,
  StoreService,
  ToastMessagesService,
  UsersService,
} from '@services';
import { of, Subscription } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { MyMealsService } from '../my-meals.service';

@Component({
  selector: 'aboca-my-meal-edit',
  templateUrl: './my-meal-edit.component.html',
  styleUrls: ['./my-meal-edit.component.scss'],
})
export class MyMealEditComponent implements OnInit, OnDestroy {
  @Input()
  meal: MyMeal | SuggestedMeal;
  @Input()
  usedAsComponent = false;
  @Input()
  readonly = false;
  @Input()
  hasContent = false;
  @Input()
  combinationTypeId: ECombinationType = undefined;
  @Input()
  addToMyRecipesVisible = false;

  @Output()
  closed = new EventEmitter<void>();

  form: FormGroup;
  ECombinationType = ECombinationType;
  state: 'NORMAL' | 'ADDING' | 'EDIT' = 'NORMAL';
  selectedRecord: MyMealRecord;
  records: MyMealRecord[] = [];
  translations = Object.entries(CombinationTypeTranslationMap).map(e => ({
    key: parseFloat(e[0]),
    value: e[1],
  }));

  private subscription = new Subscription();
  private originalRecordsId: string[];
  private reloadOnClose = false;
  private needsSave = false;

  constructor(
    private usersService: UsersService,
    private storeService: StoreService,
    private bitfMatSidenavService: BitfMatSidenavService,
    private formBuilder: FormBuilder,
    private abocaBatchService: AbocaBatchService,
    private loaderService: LoaderService,
    private translateService: TranslateService,
    private toastMessagesService: ToastMessagesService,
    private myMealsService: MyMealsService,
    private suggestedMealsService: SuggestedMealsService,
    private dialogsService: DialogsService,
    public foodProductService: FoodProductService,
    @Optional() private dialogRef: MatDialogRef<MyMealEditComponent>,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    data: {
      meal?: MyMeal | SuggestedMeal;
      combinationTypeId?: ECombinationType;
      readonly?: boolean;
      addToMyRecipesVisible?: boolean;
    }
  ) {
    data = data || {};
    this.meal = data.meal;
    this.combinationTypeId = data.combinationTypeId;
    this.readonly = data.readonly;
    this.addToMyRecipesVisible = data.addToMyRecipesVisible;
  }

  ngOnInit() {
    let meal$ = of(this.meal);
    if (!this.readonly && this.meal === undefined) {
      meal$ = this.createEmptyMeal();
    }

    meal$.subscribe((meal: MyMeal) => {
      this.meal = meal;
      this.originalRecordsId = this.meal.records.map(record => record.id);
      this.records = this.meal.records.map(record => new MyMealRecord(record.serialised));
      this.form = this.formBuilder.group({
        mealLabel: [this.meal.label, Validators.required],
      });

      this.subscription.add(
        this.form.valueChanges.subscribe(() => {
          this.needsSave = true;
        })
      );
    });
  }

  onDeleteMeal() {
    this.myMealsService.deleteMeal(this.meal.id).subscribe(() => {
      this.reloadOnClose = true;
      this.onClose();
    });
  }

  onAddToDiary() {
    of(undefined)
      .pipe(
        switchMap(() => (this.needsSave ? this.save() : of(undefined))),
        switchMap(() => this.myMealsService.addMealToDiary(this.meal.id, this.addToMyRecipesVisible))
      )
      .subscribe(() => this.onClose());
  }

  onAddToMyRecipes() {
    of(undefined)
      .pipe(
        switchMap(() => this.suggestedMealsService.copyToUserMeals(this.meal.id)),
        switchMap(() => this.translateService.get(bitfToTranslate('FAVORITES.MY_MEALS.ADDED_TO_MY_RECIPES'))),
        tap((message: string) => {
          this.toastMessagesService.show({
            type: EBitfUiMessageType.SUCCESS,
            title: message,
          });
        }),
        map(() => {})
      )
      .subscribe();
  }

  onClose({ save }: { save: boolean } = { save: true }) {
    of(save && this.needsSave && !this.readonly)
      .pipe(
        switchMap(canSave => {
          if (this.needsSave && !save) {
            return this.dialogsService.dialog
              .open(CONSTANTS.okCancelDialogComponent, {
                data: {
                  title: bitfToTranslate('FAVORITES.SAVE_DIALOG.TITLE'),
                  message: bitfToTranslate('FAVORITES.SAVE_DIALOG.TEXT'),
                  okText: bitfToTranslate('COMMON.YES'),
                  cancelText: bitfToTranslate('COMMON.BUTTON.NO'),
                },
              })
              .afterClosed()
              .pipe(
                filter(
                  (dialogRes: IBitfCloseEvent<void>) =>
                    dialogRes && dialogRes.status === EBitfCloseEventStatus.OK
                )
              );
          }
          return of(canSave);
        }),
        switchMap(canSave => (canSave ? this.save() : of(undefined))),
        tap(() => {
          const data = { reload: this.reloadOnClose };
          this.closed.emit();
          if (!this.usedAsComponent) {
            if (this.dialogRef) {
              this.dialogRef.close({
                status: EBitfCloseEventStatus.OK,
                data,
              });
            } else {
              this.bitfMatSidenavService.close({
                status: EBitfCloseEventStatus.CLOSE,
                data,
              });
            }
          }
        })
      )
      .subscribe();
  }

  onAdd() {
    this.state = 'ADDING';
  }

  onSearchClosed() {
    this.state = 'NORMAL';
  }

  onSelectedRecord(record: MyMealRecord) {
    this.selectedRecord = record;
    this.state = 'EDIT';
  }

  onEditBack() {
    this.selectedRecord = undefined;
    this.state = 'NORMAL';
  }

  onEditApply(newQuantity: number) {
    this.selectedRecord.setQuantity(newQuantity);
    this.needsSave = true;
    this.onEditBack();
  }

  onSelectedDelete() {
    const index = this.records.indexOf(this.selectedRecord);
    if (index >= 0) {
      this.records.splice(index, 1);
    }
    this.state = 'NORMAL';
    this.needsSave = true;
  }

  onItemAdded(result: ISearchResult) {
    const mealRecord = new MyMealRecord({
      userMealId: this.meal.id,
      productId: result.toAdd.id,
      quantity: result.quantity,
      product: result.toAdd,
    });
    this.records.push(mealRecord);
    this.state = 'NORMAL';
    this.needsSave = true;
    if (this.meal instanceof MyMeal) {
      this.meal.records.push(mealRecord);
    }
  }

  get deleteEnabled() {
    return this.records.length > 0 && this.meal.id !== undefined;
  }

  onSave() {
    this.save().subscribe();
  }

  private save() {
    this.reloadOnClose = true;
    const recordIdSet = new Set<string>(this.records.map(record => record.id));
    const deletedRecordIds: string[] = this.originalRecordsId.filter(
      originalRecordId => !recordIdSet.has(originalRecordId)
    );

    const myMealRequest = this.getUpdateOrCreateMyMealRequest();
    const myMealRequestUrl = myMealRequest.method === 'POST' ? '$1' : myMealRequest.url;
    const requests: IBitfJsonBatchRequest[] = [myMealRequest]
      .concat(
        this.records.map((record: MyMealRecord) =>
          this.getUpdateOrCreateMyMealRecordRequest(myMealRequestUrl, record)
        )
      )
      .concat(
        deletedRecordIds.map((recordId: string) =>
          this.getDeleteMyMealRecordRequest(myMealRequestUrl, recordId)
        )
      )
      .map(r => {
        r.atomicityGroup = 'group1';
        return r;
      });

    this.loaderService.show();
    return this.abocaBatchService.jsonBatch(requests).pipe(
      map(response => {
        const hasError = (response.content.data || []).some(
          (res: { status: number }) => res.status < 200 || res.status >= 300
        );
        this.needsSave = hasError;
        const mealId: string = ((response.content.data || [{}])[0].body || { id: this.meal.id }).id;
        return { saved: !hasError, mealId };
      }),
      switchMap(({ saved, mealId }) => {
        const type = saved ? EBitfUiMessageType.SUCCESS : EBitfUiMessageType.ERROR;
        const message = saved
          ? bitfToTranslate('FAVORITES.EDIT_MEAL.SAVED')
          : bitfToTranslate('FAVORITES.EDIT_MEAL.ERROR_ON_SAVE');

        if (saved) {
          this.meal.id = mealId;
        }

        return this.translateService.get(message).pipe(map(title => ({ title, type })));
      }),
      tap(({ title, type }) => {
        this.toastMessagesService.show({
          type,
          title,
        });
      })
    );
  }

  private getUpdateOrCreateMyMealRequest(): IBitfJsonBatchRequest {
    const needsMealCreation = this.meal.id === undefined;
    const method = needsMealCreation ? 'POST' : 'PATCH';
    let url = `users(${this.storeService.store.user.id})/myMeals`;
    if (method === 'PATCH') {
      url += `(${this.meal.id})`;
    }
    return {
      id: '1',
      method,
      url,
      body: {
        label: this.form.get('mealLabel').value,
        combinationTypeId: ECombinationType.MY_RECIPES,
      },
    };
  }

  private getUpdateOrCreateMyMealRecordRequest(prevUrl: string, record: MyMealRecord): IBitfJsonBatchRequest {
    const isNew = record.id === undefined;
    const method = isNew ? 'POST' : 'PATCH';
    let url = `${prevUrl}/records`;
    if (method === 'PATCH') {
      url += `(${record.id})`;
    }
    return {
      method,
      dependsOn: ['1'],
      url,
      rawUrl: prevUrl === '$1',
      body: {
        productId: method === 'PATCH' ? undefined : record.productId,
        quantity: record.quantity,
      },
    };
  }

  private getDeleteMyMealRecordRequest(prevUrl: string, recordId: string): IBitfJsonBatchRequest {
    return {
      method: 'DELETE',
      dependsOn: ['1'],
      rawUrl: prevUrl === '$1',
      url: `${prevUrl}/records(${recordId})`,
    };
  }

  private createEmptyMeal() {
    return this.usersService.getDefaultMyMealLabel().pipe(
      map(label => {
        return new MyMeal({
          label,
          combinationTypeId: this.combinationTypeId || ECombinationType.MY_LUNCHES,
        });
      })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
