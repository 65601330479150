import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FoodGroup } from '@models';

@Component({
  selector: 'aboca-food-group',
  templateUrl: './food-group.component.html',
  styleUrls: ['./food-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FoodGroupComponent implements OnInit {
  @Input()
  foodGroup: FoodGroup;
  @Input()
  hasActionRow = true;

  ngOnInit() {}
}
