import { Injectable, Injector } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

import { User, DietMenuProposal, Diet, Store } from '@models';

import { ResponsiveService } from '@services';
import { EStoreActions } from '@enums';
import { BitfAppSessionService } from '@bitf/core/services/app-session/bitf-app-session.service';
import { BitfTagManagerService } from '@bitf/core/services/tag-manager/bitf-tag-manager.service';
import { updateAppLayout, initDynamicLayoutUrl } from '@bitf/utils/bitf-responsive.utils';

@Injectable({
  providedIn: 'root',
})
export class AppSessionService extends BitfAppSessionService {
  wizardUser: User;
  dietMenuProposal: DietMenuProposal;
  diet: Diet;

  constructor(
    protected injector: Injector,
    private breakpointObserver: BreakpointObserver,
    public responsiveService: ResponsiveService,
    private bitfTagManagerService: BitfTagManagerService
  ) {
    super(injector);
  }

  init() {
    super.init();

    // Reponsive mangement
    initDynamicLayoutUrl(this.router, this.storeService);
    this.initBreakpointListener();
    this.responsiveService.init();

    this.bitfTagManagerService.init();
  }

  initSettings() {}

  initLogSender() {}

  initBreakpointListener() {
    this.breakpointObserver
      .observe([Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge])
      .subscribe(result => {
        if (result.matches) {
          this.storeService.setStore((store: Store) => {
            store.activeBreakpoints.isDesktop = true;
            store.activeBreakpoints.isMobile = false;
          }, EStoreActions.BREACKPOINT);
        } else {
          this.storeService.setStore((store: Store) => {
            store.activeBreakpoints.isDesktop = false;
            store.activeBreakpoints.isMobile = true;
          }, EStoreActions.BREACKPOINT);
        }
        updateAppLayout(this.router, result.matches);
      });
  }

  cleanSession() {}
}

/* EApiCallStateNames SHOULD BE IN common-enums/app.enums.ts but it is not working when moved there */
