import { Component, ChangeDetectionStrategy, Input, OnInit, OnChanges } from '@angular/core';

import { NutritionData, Food } from '@models';

@Component({
  selector: 'aboca-food-nutrients-table',
  templateUrl: './food-nutrients-table.component.html',
  styleUrls: ['./food-nutrients-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FoodNutrientsTableComponent implements OnChanges {
  @Input()
  nutritionData: NutritionData | Food;
  @Input()
  quantity: number;
  @Input()
  subHeader = '';

  multiplier: number;

  ngOnChanges() {
    let referenceQuantity = this.nutritionData['quantity'] || 100;
    if (this.nutritionData instanceof Food) {
      this.nutritionData = this.nutritionData.product
        ? this.nutritionData.product.nutrients
        : this.nutritionData;
      referenceQuantity = 100;
    }
    this.quantity = this.quantity || this.nutritionData['quantity'] || 100;
    this.multiplier = this.quantity / referenceQuantity;
  }
}
