import { Directive } from '@angular/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { StoreService } from '@services';

@Directive({
  selector: 'mat-datepicker[bitfResponsiveTouchUi]',
})
export class BitfResponsiveTouchUiDirective {
  constructor(host: MatDatepicker<Date>, storeService: StoreService) {
    host.touchUi = storeService.store.activeBreakpoints.isMobile;
  }
}
