import { NgModule, ModuleWithProviders, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MATERIAL_MODULES, CDK_MODULES } from './material-modules';
import { DateAdapter } from '@angular/material';

import { CommonSharedModule } from '@common/shared/common-shared.module';

import { BitfLoaderComponent } from '@bitf/core/components/ui/loader/bitf-loader.component';
import { BitfMatOkCancelDialogComponent } from '@bitf/core/components/ui/ok-cancel-dialog/material';

import { RoundProgressModule } from 'angular-svg-round-progressbar';
import { EcoFabSpeedDialModule } from '@ecodev/fab-speed-dial';

// tslint:disable-next-line:max-line-length
import { VerticalCenteredContainerComponent } from '@shared/components/vertical-centered-container/vertical-centered-container.component';
import { WizardFooterComponent } from '@shared/components/wizard/wizard-footer/wizard-footer.component';
import { WizardHeaderComponent } from '@shared/components/wizard/wizard-header/wizard-header.component';
import { BitfResponsiveTouchUiDirective } from '@common/directives';
import { BitfDatePipe } from '@bitf/pipes/bitf-date.pipe';
import {
  DASHBOARD_COMPONENTS,
  DASHBOARD_ENTRY_COMPONENTS,
} from '@web/modules/dashboard/dashboard-entry-components';
import { BitfDecimalPipe } from '@bitf/pipes/bitf-decimal.pipe';
import { MenuItemBadgeDirective } from '@web/directives/menu-item-badge.directive';
import { ItemInfoRowComponent } from './components/item-info-row/item-info-row.component';
import {
  FAVORITE_COMPONENTS,
  FAVORITE_ENTRY_COMPONENTS,
} from '@web/modules/favorites/favorite-entry-components';
import { CenteredAddButtonComponent } from './components/centered-add-button/centered-add-button.component';
import { BitfDateAdapterService } from '@bitf/services/locale/bitf-date-adapter.service';
import { BitfDynamicLocaleService } from '@bitf/core/services/locale/bitf-dynamic-locale.service';
// tslint:disable-next-line:max-line-length
import { BitfUiRoleManagerDirective } from '@common/libs/bitforce/directives/ui-role-manager/bitf-ui-role-manager.directive';

const SHARED_MODULES = [
  CommonSharedModule,
  RouterModule,
  MATERIAL_MODULES,
  CDK_MODULES,
  RoundProgressModule,
  EcoFabSpeedDialModule,
];
const SHARED_COMPONENTS = [
  BitfMatOkCancelDialogComponent,
  BitfLoaderComponent,
  VerticalCenteredContainerComponent,
  WizardHeaderComponent,
  WizardFooterComponent,
  ItemInfoRowComponent,
  CenteredAddButtonComponent,
  BitfUiRoleManagerDirective,
  ...DASHBOARD_COMPONENTS,
  ...FAVORITE_COMPONENTS,
];
const SHARED_ENTRY_COMPONENTS = [
  BitfMatOkCancelDialogComponent,
  ...DASHBOARD_ENTRY_COMPONENTS,
  ...FAVORITE_ENTRY_COMPONENTS,
];
const SHARED_DIRECTIVES = [BitfResponsiveTouchUiDirective, MenuItemBadgeDirective];
const SHARED_PIPES = [BitfDatePipe, BitfDecimalPipe];

@NgModule({
  imports: [...SHARED_MODULES],
  exports: [...SHARED_MODULES, ...SHARED_COMPONENTS, ...SHARED_DIRECTIVES, ...SHARED_PIPES],
  declarations: [...SHARED_COMPONENTS, ...SHARED_DIRECTIVES, SHARED_PIPES],
  entryComponents: [...SHARED_ENTRY_COMPONENTS],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    {
      provide: LOCALE_ID,
      deps: [BitfDynamicLocaleService],
      useFactory: (dynamicLocaleService: BitfDynamicLocaleService) => dynamicLocaleService.defaultLocale.code,
    },
    { provide: DateAdapter, useClass: BitfDateAdapterService },
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: [],
    };
  }
}
