import { Injector } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { BitfAuthService } from '../bitf-auth.service';
import { IBitfQueryStringLogin } from './bitf-query-string-token-auth.interface';
import { EBitfAuthState } from '@enums';
import { User } from '@models';
import { IBitfApiResponse, IBitfTokenMetadata } from '@interfaces';

export abstract class BitfQueryStringTokenAuthService<T extends IBitfTokenMetadata> extends BitfAuthService<
  IBitfQueryStringLogin,
  T
> {
  protected activatedRoute: ActivatedRoute;

  constructor(injector: Injector) {
    super(injector);
    this.activatedRoute = injector.get(ActivatedRoute);
  }

  handleAuthentication(queryParamVarName) {
    let encodedToken = this.activatedRoute.snapshot.queryParamMap.get(queryParamVarName);
    if (!encodedToken && this.isTokenValid()) {
      encodedToken = this.authTokenMetaData.token;
    }

    if (encodedToken) {
      this.authState$.next(EBitfAuthState.LOGIN_IN_PROGRESS);
      this.signIn({
        encodedToken,
      });
      this.usersService.getMe().subscribe(
        (response: IBitfApiResponse<User>) => {
          this.setUser(response.content);
          this.authState$.next(response.content ? EBitfAuthState.LOGGED : EBitfAuthState.LOGIN_ERROR);
        },
        () => this.authState$.next(EBitfAuthState.LOGIN_ERROR)
      );
    } else {
      this.authState$.next(EBitfAuthState.LOGIN_ERROR);
    }
  }
}
