import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';

import { AuthService } from '@services';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { EBitfInterceptors } from '../enums';

export class BitfRenewTokenInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.headers.has(EBitfInterceptors.BITF_RENEW_TOKEN_INTERCEPTOR)) {
      const newReq = req.clone({
        headers: req.headers.delete(EBitfInterceptors.BITF_RENEW_TOKEN_INTERCEPTOR),
      });
      return next.handle(newReq);
    }

    return next.handle(req).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          const newToken = this.authService.extractRenewToken(event);
          if (newToken !== null) {
            this.authService.signInWithToken(newToken);
          }
        }
      })
    );
  }
}
