<div class="d-flex flex-column h-100">
  <mat-toolbar class="aboca-toolbar">
    <mat-toolbar-row *ngIf="!closeButton">
      <aboca-header-back [title]="food.foodName"
        class="flex-grow-1"
        (back)="onBack()"></aboca-header-back>
    </mat-toolbar-row>

    <mat-toolbar-row *ngIf="closeButton">
      <aboca-header-close [title]="food.foodName"
        class="flex-grow-1"
        (close)="onClose()"></aboca-header-close>
    </mat-toolbar-row>
  </mat-toolbar>

  <div class="flex-grow-1 flex-shrink-1 container-fluid aboca-scroll-y">
    <aboca-quantity-row [disabled]="readonly"
      units="{{'UNITS.GRAMS_OR_ML' | translate}}"
      [kiloCalories]="kiloCalories"
      [referenceQuantity]="100"
      [quantity]="food.quantity"
      [favoriteId]="food.foodProductId"
      (quantityChange)="onQuantityChange($event)"
      (submit)="onApply()">
    </aboca-quantity-row>

    <aboca-food-nutrients-table [nutritionData]="food"
      quantity="{{newQuantity}}"
      [subHeader]=""></aboca-food-nutrients-table>
  </div>

  <div class="container-fluid flex-shrink-0 flex-grow-0 border-top">
    <div class="row">
      <div *ngIf="!readonly"
        class="col d-flex flex-row flex-grow-1">
        <button mat-stroked-button
          *ngIf="!readonly"
          class="m-1 col aboca-border-primary"
          (click)="onDelete()"
          color="secondary">{{'COMMON.DELETE' | translate}}</button>

        <button mat-flat-button
          *ngIf="!readonly"
          class="m-1 col"
          (click)="onApply()"
          color="primary">{{'COMMON.APPLY' | translate}}</button>

        <button mat-flat-button
          *ngIf="readonly"
          class="m-1 col"
          (click)="onBack()"
          color="primary">{{'COMMON.CLOSE' | translate}}</button>
      </div>
    </div>
  </div>
</div>