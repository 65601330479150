import { EApiRequestPartKeys, EBitfApiRequestPartsNames } from '@enums';
import { BitfApiRequestPart } from '@bitf/core/api-call-state/bitf-api-request-part';
import { bitfFormatDateISO } from '@bitf/utils/bitf-dates.utils';

export class DaySelectorApiRequestPart extends BitfApiRequestPart {
  constructor() {
    super(EApiRequestPartKeys.FILTERS, EBitfApiRequestPartsNames.FILTER, {
      data: {
        date: new Date(),
      },
    });
  }

  build(): void {
    this.part = undefined;
    if (!this.data.date) {
      return;
    }

    this.part = `date eq ${bitfFormatDateISO(this.data.date)}`;
  }
}
