import { Component, Inject, OnInit, OnDestroy, Optional, DoCheck } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, FormArray, FormControl } from '@angular/forms';
import { Subject } from 'rxjs';

import {
  Meal,
  NutritionData,
  Food,
  SearchFoodResult,
  DiaryActivity,
  PhysicalActivity,
  FoodGroup,
} from '@models';
import { EBitfCloseEventStatus, ERecordState } from '@enums';
import { ISearchResult, IBitfApiResponse } from '@interfaces';
import { DailyDiaryService, FoodProductService, PhysicalActivityService, LoaderService } from '@services';
import { ICardModel } from '../../shared-dashboard/dashboard-diary/diary-card/diary-card.interface';
import { takeUntil } from 'rxjs/operators';
import { BitfApiService } from '@bitf/core/services/api/bitf-api.service';
import { BitfMatSidenavService } from '@bitf/core/services/sidenav/material/bitf-mat-sidenav.service';
import { bitfToTranslate } from '@bitf/utils/bitf-translate.utils';
import { bitfSelectAllCheckbox } from '@bitf/utils/bitf-form.utils';

@Component({
  selector: 'aboca-verification',
  templateUrl: './verification.component.html',
  styleUrls: ['./verification.component.scss'],
})
export class VerificationComponent implements OnInit, OnDestroy, DoCheck {
  // Input data
  model: ICardModel;
  dietDayId: string;

  // controller data
  form: FormGroup;
  nutritionData: NutritionData;
  state: 'NORMAL' | 'ADDING' | 'EDIT' = 'NORMAL';
  searchService: BitfApiService;
  fetchAll: boolean;
  addLabel: string;
  selectedItemIndex: number;
  nonGroupedShift = 0;
  groups: { shift: number; foodGroup: FoodGroup; selectAllControl: FormControl }[];

  private unsubscribe$ = new Subject<void>();
  private selectedMap = new Map<string, boolean>();

  constructor(
    private formBuilder: FormBuilder,
    private loaderService: LoaderService,
    private dailyDiaryService: DailyDiaryService,
    private bitfMatSidenavService: BitfMatSidenavService,
    private foodProductService: FoodProductService,
    private physicalActivityService: PhysicalActivityService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    data: { model: ICardModel; dietDayId: string },
    @Optional() private dialogRef: MatDialogRef<VerificationComponent>
  ) {
    // If opened inside mat dialog, data will contain model and dietDayId.
    // If opened inside bitfSideNav, this.model and this.dietDayId will be set just after the constructor
    const { model, dietDayId } = data || { model: undefined, dietDayId: undefined };
    this.model = model;
    this.dietDayId = dietDayId;
  }

  ngOnInit() {
    const isMeal = this.model instanceof Meal;
    this.searchService = isMeal ? this.foodProductService : this.physicalActivityService;
    this.fetchAll = !isMeal;
    this.addLabel = isMeal
      ? bitfToTranslate('COMMON.ADD_A_FOOD')
      : bitfToTranslate('ACTIVITIES.ADD_ACTIVITY');

    const selected = this.formBuilder.array(
      this.model.records.map((_, i) =>
        this.formBuilder.control(this.model.records[i].state !== ERecordState.INSERTED)
      )
    );
    this.form = this.formBuilder.group({
      selectAll: [false],
      selected,
      notes: [this.model.notes],
    });
    this.form.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(() => this.updateSelectedMap());
    bitfSelectAllCheckbox(selected, this.form.get('selectAll'), this.unsubscribe$);

    let counter = 0;
    this.groups = this.model.foodGroups.map(group => {
      const item = {
        foodGroup: group,
        selectAllControl: new FormControl(false),
        shift: counter,
      };

      bitfSelectAllCheckbox(
        selected.controls.slice(counter, counter + group.foods.length),
        item.selectAllControl,
        this.unsubscribe$
      );
      counter += group.foods.length;

      return item;
    });

    this.updateNutritionData();

    if (this.model && this.model.foodGroups) {
      this.nonGroupedShift = this.model.foodGroups.reduce((count, group) => count + group.foods.length, 0);
    }
  }

  ngDoCheck() {
    if (this.model.records.length !== this.getSelectedControl().length) {
      this.form.setControl('selected', this.createCheckboxControls());
    }
  }

  getSelectedControl(): FormArray {
    return this.form.get('selected') as FormArray;
  }

  private updateSelectedMap() {
    this.selectedMap.clear();
    this.getSelectedControl().controls.forEach((control, i) => {
      this.selectedMap.set(this.model.records[i].id, control.value);
    });
  }

  private createCheckboxControls() {
    return this.formBuilder.array(
      this.model.records.map(record => this.formBuilder.control(this.selectedMap.get(record.id)))
    );
  }

  private updateNutritionData() {
    if (this.model instanceof Meal) {
      this.nutritionData = this.model.getNutritionData();
    }
  }

  onClose({ confirmed = false } = {}) {
    const itemsCheckedState = this.getSelectedControl().controls.map(control => control.value);
    const notes = this.form.value.notes;
    if (this.dialogRef) {
      this.dialogRef.close({
        status: EBitfCloseEventStatus.OK,
        data: { itemsCheckedState, confirmed, notes },
      });
    } else {
      this.bitfMatSidenavService.close({
        status: EBitfCloseEventStatus.CLOSE,
        data: { itemsCheckedState, confirmed, notes },
      });
    }
  }

  onConfirm() {
    this.onClose({ confirmed: true });
  }

  onAddItem() {
    this.state = 'ADDING';
  }

  onItemAdded(result: ISearchResult) {
    if (this.model instanceof Meal) {
      this.addFood(result);
    } else {
      this.addPhysicalActivity(result);
    }
  }

  private addPhysicalActivity(result: ISearchResult) {
    const activity = <PhysicalActivity>result.toAdd;
    this.state = 'NORMAL';

    this.loaderService.show();
    this.dailyDiaryService
      .insertActivity({
        dietDayId: this.dietDayId,
        activity,
        duration: result.quantity,
      })
      .subscribe(
        (response: IBitfApiResponse<DiaryActivity>) => {
          response.content.activity = activity;
          this.updateRecordsWithDataFromServer(response);
        },
        () => this.removeInsertedItem()
      );
  }

  private addFood(result: ISearchResult) {
    const model = <Meal>this.model;
    this.state = 'NORMAL';
    this.insertSelectedFood(result);

    this.loaderService.show();
    this.dailyDiaryService
      .insertFoodInMeal({
        dietDayId: this.dietDayId,
        mealId: model.id,
        foodId: result.toAdd.id,
        quantity: result.quantity,
        state: ERecordState.INSERTED_DURING_VERIFICATION,
      })
      .subscribe(
        (response: IBitfApiResponse<Food>) => {
          this.updateRecordsWithDataFromServer(response);
        },
        () => this.removeInsertedItem()
      );
  }

  onSearchClosed() {
    this.state = 'NORMAL';
  }

  isItemRowEditable(item: Food | PhysicalActivity) {
    return item instanceof Food;
  }

  onEdit(itemIndex: number) {
    this.selectedItemIndex = itemIndex;
    this.state = 'EDIT';
  }

  onEditBack() {
    this.selectedItemIndex = undefined;
    this.state = 'NORMAL';
  }

  onEditApply(newQuantity: number) {
    this.loaderService.show();
    const selectedItem = <Food>this.model.records[this.selectedItemIndex];
    const quantity = newQuantity;
    const meal = <Meal>this.model;
    this.dailyDiaryService
      .updateFood({
        dietDayId: this.dietDayId,
        mealId: meal.id,
        foodId: selectedItem.id,
        quantity,
      })
      .subscribe(() => {
        selectedItem.quantity = quantity;
        meal.records[this.selectedItemIndex] = new Food(selectedItem.serialised, newQuantity);
        this.onEditBack();
      });
  }

  onSelectedDelete() {
    const meal = <Meal>this.model;
    this.loaderService.show();
    this.dailyDiaryService
      .deleteFood({
        dietDayId: this.dietDayId,
        mealId: meal.id,
        foodId: this.model.records[this.selectedItemIndex].id,
      })
      .subscribe(() => {
        meal.records.splice(this.selectedItemIndex, 1);
        this.onEditBack();
      });
  }

  private insertSelectedFood(result: ISearchResult) {
    const foodToAdd = <SearchFoodResult>result.toAdd;
    this.model.records.push(
      new Food(
        Object.assign({}, foodToAdd.nutrients, {
          foodName: foodToAdd.name,
          quantity: result.quantity,
        })
      )
    );
    this.getSelectedControl().push(this.formBuilder.control(false));
    this.updateNutritionData();
  }

  private updateRecordsWithDataFromServer(response: IBitfApiResponse<any>) {
    const numRecords = this.model.records.length - 1;
    this.model.records[numRecords] = response.content;
    this.updateNutritionData();
  }

  private removeInsertedItem() {
    const numRecords = this.model.records.length - 1;
    this.model.records.splice(numRecords, 1);
    this.getSelectedControl().removeAt(numRecords);
    this.updateNutritionData();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
