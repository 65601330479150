import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'aboca-favorites-header',
  templateUrl: './favorites-header.component.html',
  styleUrls: ['./favorites-header.component.scss'],
})
export class FavoritesHeaderComponent {
  @Input()
  title: string;
  @Input()
  saveIcon = false;

  @Output()
  close = new EventEmitter<{ save: boolean }>();
}
