import { SuperModel } from './super-model.model';
import { ERecordState } from '@enums';
import { bitfToTranslate } from '@bitf/utils/bitf-translate.utils';

export class DiaryActivity extends SuperModel {
  diaryId: number;
  activityId: number;
  notes: string;
  duration: number;
  state: ERecordState;
  activity: PhysicalActivity;
  kiloCalories?: number;

  constructor(object: any) {
    super(object);
    if (this.activity) {
      this.activity = new PhysicalActivity(this.activity);
    }
  }

  get name() {
    return this.activity.description;
  }

  get quantity() {
    return this.duration;
  }

  get unit() {
    return bitfToTranslate('UNITS.MINUTES');
  }

  getKCalories(weight: number) {
    return this.kiloCalories != null ? this.kiloCalories : this.activity.getKCalories(weight, this.duration);
  }

  getReferenceKCalories(weight: number) {
    return this.activity.getKCalories(weight, this.referenceQuantity);
  }

  get referenceQuantity() {
    return 60;
  }

  get serialised() {
    return {
      id: this.id,
      diaryId: this.diaryId,
      activityId: this.activityId,
      duration: this.duration,
      state: this.state,
    };
  }
}

export class PhysicalActivity extends SuperModel {
  description: string;
  mets: number;
  categoryId: number;
  typeId: number;
  category?: PhysicalActivityCategory;

  get name() {
    return this.description;
  }

  getKCalories(weight: number, minutes: number) {
    return (this.mets * weight * minutes) / 60;
  }

  get serialised() {
    return {
      id: this.id,
      mets: this.mets,
      categoryId: this.categoryId,
      typeId: this.typeId,
      category: this.category && this.category.serialised,
    };
  }
}

export class PhysicalActivityCategory extends SuperModel {
  description: string;
  position: number;

  get serialised() {
    return {
      id: this.id,
      description: this.description,
      position: this.position,
    };
  }
}
