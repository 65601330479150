import { SuperModel } from './super-model.model';
import { ERecordState } from '@enums';
import { SearchFoodResult } from '@web/core/models';

export class NutritionData extends SuperModel {
  fats: number;
  kiloCalories: number;
  saturedFats: number;
  proteins: number;
  carbohydrates: number;
  sugar: number;
  fibers: number;
  sodium: number;
  foodProductId: string;
  quantity: number;
  product?: SearchFoodResult;

  constructor(object: any, quantity: number = 100) {
    super(object);

    this.quantity = quantity;

    // null * x = 0, here we want undefined for null values
    this.nullToUndefined();

    if (this.product) {
      this.product = new SearchFoodResult(this.product);

      const nutrients100 = this.product.nutrients;
      const multiplier = quantity / 100;
      Object.assign(this, {
        fats: nutrients100.fats * multiplier,
        kiloCalories: nutrients100.kiloCalories * multiplier,
        saturedFats: nutrients100.saturedFats * multiplier,
        proteins: nutrients100.proteins * multiplier,
        carbohydrates: nutrients100.carbohydrates * multiplier,
        sugar: nutrients100.sugar * multiplier,
        fibers: nutrients100.fibers * multiplier,
        sodium: nutrients100.sodium * multiplier,
      });
    }
  }

  get unsaturatedFats() {
    return this.fats - this.saturedFats;
  }

  get serialised() {
    return {
      id: this.id,
      fats: this.fats,
      kiloCalories: this.kiloCalories,
      saturedFats: this.saturedFats,
      proteins: this.proteins,
      carbohydrates: this.carbohydrates,
      sugar: this.sugar,
      fibers: this.fibers,
      sodium: this.sodium,
      foodProductId: this.foodProductId,
    };
  }

  private nullToUndefined() {
    Object.keys(this).forEach(key => {
      if (this[key] === null) {
        this[key] = undefined;
      }
    });
  }
}

export class FoodGroup extends SuperModel {
  groupId: string;
  groupName: string;
  foods: Food[] = [];

  // Used to make FoodGroup be similar to Food
  quantity = null;
  state: ERecordState = undefined;

  constructor(object: any = {}) {
    super(object);
  }

  getTotalKCalories(): number {
    return this.foods.reduce((accumulator, currentValue) => accumulator + currentValue.kiloCalories, 0);
  }

  get name() {
    return this.groupName;
  }

  get serialised() {
    return {};
  }
}

export class Food extends NutritionData {
  quantity: number;
  unit: string;
  state: ERecordState;
  foodProductId: string;
  foodName: string;
  groupId?: string;
  groupName?: string;

  constructor(obj: any, newQuantity?: number) {
    super(obj, newQuantity !== undefined ? newQuantity : obj.quantity);
  }

  get name() {
    return this.foodName;
  }

  get serialised() {
    return {
      ...new NutritionData(this).serialised,
      quantity: this.quantity,
      unit: this.unit,
      state: this.state,
      foodProductId: this.foodProductId,
      foodName: this.foodName,
      product: this.product && this.product.serialised,
    };
  }
}
