<div class="mt-1 negative-margin">
  <mat-expansion-panel [expanded]="false"
    #expansionPanel
    [ngClass]="{'mat-elevation-z0': expansionPanel.expanded === false}"
    class="border aboca-border-primary">
    <mat-expansion-panel-header class="pl-2"
      collapsedHeight="54px">
      <aboca-item-row [item]="foodGroup"
        [editable]="false"></aboca-item-row>
    </mat-expansion-panel-header>
    <ng-content></ng-content>

    <mat-action-row *ngIf="hasActionRow">
      <ng-content select="[mat-action-row]"></ng-content>
    </mat-action-row>
  </mat-expansion-panel>
</div>