import { SuperModel } from './super-model.model';
import { NutritionData } from './food.model';
import { Manufacturer } from './manufacturer.model';

export class SearchFoodResult extends SuperModel {
  static readonly defaultQuantity = 100;

  name: string;
  brand: string;
  nutrients: NutritionData;
  isFavorite: boolean;

  manufacter?: Manufacturer;
  quantity = SearchFoodResult.defaultQuantity;

  private kcalFor100g: number;

  constructor(data: any) {
    super(data);
    this.nutrients = new NutritionData(this.nutrients);
    this.kcalFor100g = this.nutrients.kiloCalories;
    if (this.manufacter) {
      this.manufacter = new Manufacturer(this.manufacter);
    }
  }

  setQuantity(quantity: number) {
    this.quantity = quantity;
    this.nutrients.kiloCalories = Math.round(
      (this.kcalFor100g * this.quantity) / SearchFoodResult.defaultQuantity
    );
  }

  get serialised() {
    return {
      name: this.name,
      brand: this.brand,
      nutrients: this.nutrients.serialised,
      isFavorite: this.isFavorite,
      manufacter: this.manufacter && this.manufacter.serialised,
    };
  }
}
