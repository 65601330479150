import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform, Inject, LOCALE_ID, ChangeDetectorRef } from '@angular/core';
import { TranslateService, TranslatePipe } from '@ngx-translate/core';
import { bitfToTranslate } from '@bitf/utils/bitf-translate.utils';
import { nullOrNaN } from '@bitf/utils/bitf-numbers.utils';

@Pipe({ name: 'bitfNumber', pure: false })
export class BitfDecimalPipe extends DecimalPipe implements PipeTransform {
  private translatePipe: TranslatePipe;

  constructor(@Inject(LOCALE_ID) locale: string, translateService: TranslateService, ref: ChangeDetectorRef) {
    super(locale);
    this.translatePipe = new TranslatePipe(translateService, ref);
  }

  transform(value: any, digitsInfo?: string, locale?: string): string | null {
    if (nullOrNaN(value)) {
      return this.translatePipe.transform(bitfToTranslate('BITF.NOT_AVAILABLE_SHORT'));
    }
    return super.transform(value, digitsInfo, locale);
  }
}
