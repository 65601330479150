<mat-toolbar color=""
  class="header">
  <div class="col-4 d-md-none"></div>
  <div class="col text-capitalize text-center text-md-left">{{'META_TAGS.DEFAULT_TITLE' | translate}}</div>
  <div class="col-4 text-right">
    <button mat-icon-button
      *ngIf="hasMenu"
      (click)="openMenu()">
      <mat-icon>person</mat-icon>
    </button>
  </div>
</mat-toolbar>