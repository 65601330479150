<div class="d-flex justify-content-around aboca-white-background mat-elevation-z2">
    <div *ngFor="let element of navbarElements"
        routerLinkActive="nav-button-selected"
        [routerLink]="element.url">
        <button mat-flat-button
            [matBadge]=""
            matBadgeSize="small"
            [abocaMenuItemBadge]="element.route"
            class="vertical-button">
            <mat-icon>{{element.icon}}</mat-icon>
            <span class="mat-small">{{element.label | translate}}</span>
        </button>
    </div>
</div>