import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DiaryActivity, Food, FoodGroup } from '@models';
import { ICardModel } from './diary-card.interface';

@Component({
  selector: 'aboca-diary-card',
  templateUrl: './diary-card.component.html',
  styleUrls: ['./diary-card.component.scss'],
})
export class DiaryCardComponent implements OnInit {
  @Input()
  cardModel: ICardModel;
  @Input()
  hasVerifyButton = false;
  @Input()
  hasDetails = false;
  @Input()
  subtitle: string;

  @Output()
  add = new EventEmitter<void>();
  @Output()
  openVerification = new EventEmitter<void>();
  @Output()
  openDetail = new EventEmitter<void>();
  @Output()
  edit = new EventEmitter<number>();
  @Output()
  addToFavorite = new EventEmitter<void>();
  @Output()
  deleteFoodGroup = new EventEmitter<number>();

  verifyButtonVisible: boolean;

  ngOnInit() {
    this.verifyButtonVisible = this.hasVerifyButton && !this.cardModel.isConfirmed;
  }

  onAdd() {
    this.add.emit();
  }

  onVerify() {
    this.openVerification.emit();
  }

  onDetail() {
    this.openDetail.emit();
  }

  onAddToFavorite() {
    this.addToFavorite.emit();
  }

  onEdit(record: Food | DiaryActivity) {
    const index = this.cardModel.records.indexOf(record);
    this.edit.next(index);
  }

  onDeleteFoodGroup(foodGroup: FoodGroup) {
    const index = this.cardModel.foodGroups.indexOf(foodGroup);
    this.deleteFoodGroup.next(index);
  }
}
