import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { Food } from '@models';

@Component({
  selector: 'aboca-food-edit',
  templateUrl: './food-edit.component.html',
  styleUrls: ['./food-edit.component.scss'],
})
export class FoodEditComponent implements OnInit {
  @Input()
  food: Food;
  @Input()
  closeButton = false;
  @Input()
  readonly = false;

  @Output()
  close = new EventEmitter<void>();
  @Output()
  back = new EventEmitter<void>();
  @Output()
  apply = new EventEmitter<number>();
  @Output()
  delete = new EventEmitter<void>();

  newQuantity: number;
  kiloCalories: number;

  constructor() {}

  ngOnInit() {
    this.newQuantity = this.food.quantity;
    this.kiloCalories = this.computeKCal();
  }

  onClose() {
    this.close.emit();
  }

  onBack() {
    this.newQuantity = this.food.quantity;
    this.back.emit();
  }

  onQuantityChange(newQuantity: number) {
    this.newQuantity = newQuantity;
    this.kiloCalories = this.computeKCal();
  }

  onApply() {
    this.apply.emit(this.newQuantity);
    this.onClose();
  }

  onDelete() {
    this.delete.emit();
  }

  private computeKCal(): number {
    return this.food.product ? this.food.product.nutrients.kiloCalories : this.food.kiloCalories;
  }
}
