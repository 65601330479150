import { Component, OnInit, Injector, OnDestroy } from '@angular/core';

import { CommonLayout } from '@common/shared/components/layout/common-layout.component';
import { NAVBAR_ELEMENTS } from '../../common/navbar-elements';

@Component({
  selector: 'aboca-desktop-layout-content',
  templateUrl: './desktop-layout-content.component.html',
  styleUrls: ['./desktop-layout-content.component.scss'],
})
export class DesktopLayoutContentComponent extends CommonLayout implements OnInit, OnDestroy {
  sections = NAVBAR_ELEMENTS;

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  updateLayout() {
    const data = this.route.snapshot.firstChild.data;
    this.showHeader = data.showHeader;
    this.showNavbar = data.showNavbar;
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
