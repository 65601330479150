import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'aboca-centered-add-button',
  templateUrl: './centered-add-button.component.html',
  styleUrls: ['./centered-add-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CenteredAddButtonComponent implements OnInit {
  @Input()
  label: string;

  @Output()
  add = new EventEmitter<void>();

  constructor() {}

  ngOnInit() {}
}
