import { SuperModel } from './super-model.model';
import { SearchFoodResult } from '@web/core/models';

export class Favorite extends SuperModel {
  userId: string;
  productId: string;

  product?: SearchFoodResult;

  constructor(data: any) {
    super(data);

    if (this.product) {
      this.product = new SearchFoodResult(this.product);
    }
  }

  get serialised() {
    return {
      id: this.id,
      userId: this.userId,
      productId: this.productId,
    };
  }
}
