import { SearchComponent } from './shared-dashboard/search/search/search.component';
import { VerificationComponent } from './shared-dashboard/verification/verification.component';
import { MealDetailsComponent } from './shared-dashboard/meal-details/meal-details.component';
import { ActivityEditComponent } from './shared-dashboard/activity-edit/activity-edit.component';
import { FoodDetailComponent } from './shared-dashboard/search/food-detail/food-detail.component';
// tslint:disable-next-line:max-line-length
import { FoodNutrientsTableComponent } from './shared-dashboard/food-nutrients-table/food-nutrients-table.component';
// tslint:disable-next-line:max-line-length
import { FoodNutrientsRowComponent } from './shared-dashboard/food-nutrients-table/food-nutrients-row/food-nutrients-row.component';
import { QuantityRowComponent } from './shared-dashboard/quantity-row/quantity-row.component';
import { HeaderBackComponent } from './shared-dashboard/header-back/header-back.component';
import { HeaderCloseComponent } from './shared-dashboard/header-close/header-close.component';
import { ItemRowComponent } from './shared-dashboard/item-row/item-row.component';
import { FoodEditComponent } from './shared-dashboard/food-edit/food-edit.component';
import { GlycemiaEditComponent } from './shared-dashboard/glycemia-edit/glycemia-edit.component';
import { DiaryCardComponent } from './shared-dashboard/dashboard-diary/diary-card/diary-card.component';
import { FoodGroupComponent } from './shared-dashboard/dashboard-diary/food-group/food-group.component';

export const DASHBOARD_COMPONENTS = [
  SearchComponent,
  VerificationComponent,
  MealDetailsComponent,
  ActivityEditComponent,
  FoodDetailComponent,
  FoodNutrientsTableComponent,
  FoodNutrientsRowComponent,
  QuantityRowComponent,
  HeaderBackComponent,
  HeaderCloseComponent,
  ItemRowComponent,
  FoodEditComponent,
  GlycemiaEditComponent,
  DiaryCardComponent,
  FoodGroupComponent,
];
export const DASHBOARD_ENTRY_COMPONENTS = [
  SearchComponent,
  VerificationComponent,
  MealDetailsComponent,
  ActivityEditComponent,
  GlycemiaEditComponent,
];
