import { SuperModel } from './super-model.model';

export class Item extends SuperModel {
  index: number;
  date: Date;
  weekNumber: number;
  weeklyWeight: number;
  theoricalWeight: number;
  newTheoricalWeight: number;

  constructor(data: any = {}) {
    super(data);
    if (this.date) {
      this.date = new Date(this.date);
    }
  }

  get serialised() {
    return {
      index: this.index,
      date: this.date,
      weekNumber: this.weekNumber,
      weeklyWeight: this.weeklyWeight,
      theoricalWeight: this.theoricalWeight,
      newTheoricalWeight: this.newTheoricalWeight,
    };
  }
}

export class WeeklyStateRange extends SuperModel {
  startDate: Date;
  endDate: Date;
  lowerLimitDelta: number;
  upperLimitDelta: number;
  dietStartWeight: number;
  dietTargetWeight: number;
  items: Item[];

  constructor(data: any = {}) {
    super(data);
    if (this.startDate) {
      this.startDate = new Date(this.startDate);
    }
    if (this.endDate) {
      this.endDate = new Date(this.endDate);
    }
    if (this.items) {
      this.items = this.items.map(e => new Item(e));
    }
  }

  get serialised() {
    return {
      startDate: this.startDate,
      endDate: this.endDate,
      lowerLimitDelta: this.lowerLimitDelta,
      upperLimitDelta: this.upperLimitDelta,
      dietStartWeight: this.dietStartWeight,
      dietTargetWeight: this.dietTargetWeight,
      items: this.items && this.items.map(e => e.serialised),
    };
  }
}
