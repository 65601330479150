<div class="mb-3">
    <h3 class="aboca-primary-color m-0">{{'FOOD_DETAIL.NUTRITIONAL_INFORMATION' | translate}}</h3>
    <div *ngIf="subHeader">{{subHeader}}</div>
</div>
<div *ngIf="nutritionData">
    <aboca-food-nutrients-row class="row no-gutters font-weight-bold"
        name="{{'NUTRITIONAL_INFORMATION.CARBOHYDRATES' | translate }}"
        type="carbohydrates"
        [value]="nutritionData.carbohydrates * multiplier"
        unit="{{'UNITS.GRAMS_SYMBOL' | translate}}"></aboca-food-nutrients-row>
    <aboca-food-nutrients-row class="row no-gutters mat-small"
        name="{{'NUTRITIONAL_INFORMATION.SUGAR' | translate }}"
        [value]="nutritionData.sugar * multiplier"
        unit="{{'UNITS.GRAMS_SYMBOL' | translate}}"></aboca-food-nutrients-row>

    <aboca-food-nutrients-row class="row no-gutters font-weight-bold my-3"
        name="{{'NUTRITIONAL_INFORMATION.PROTEINS' | translate }}"
        type="proteins"
        [value]="nutritionData.proteins * multiplier"
        unit="{{'UNITS.GRAMS_SYMBOL' | translate}}"></aboca-food-nutrients-row>

    <aboca-food-nutrients-row class="row no-gutters font-weight-bold"
        name="{{'NUTRITIONAL_INFORMATION.FATS' | translate }}"
        type="fats"
        [value]="nutritionData.fats * multiplier"
        unit="{{'UNITS.GRAMS_SYMBOL' | translate}}"></aboca-food-nutrients-row>
    <aboca-food-nutrients-row class="row no-gutters mat-small"
        name="{{'NUTRITIONAL_INFORMATION.SATURATED' | translate }}"
        [value]="nutritionData.saturedFats * multiplier"
        unit="{{'UNITS.GRAMS_SYMBOL' | translate}}"></aboca-food-nutrients-row>

    <aboca-food-nutrients-row class="row no-gutters font-weight-bold my-3"
        name="{{'NUTRITIONAL_INFORMATION.FIBERS' | translate }}"
        type="fibers"
        [value]="nutritionData.fibers * multiplier"
        unit="{{'UNITS.GRAMS_SYMBOL' | translate}}"></aboca-food-nutrients-row>

    <aboca-food-nutrients-row class="row no-gutters font-weight-bold my-3"
        name="{{'NUTRITIONAL_INFORMATION.SODIUM' | translate }}"
        type="sodium"
        [value]="nutritionData.sodium * multiplier"
        unit="{{'UNITS.GRAMS_SYMBOL' | translate}}"></aboca-food-nutrients-row>
</div>