import { EApiCallStateNames } from '@enums';
import { DaySelectorApiRequestPart, DefaultApiCallStateMapper } from './api-call-state-parts.barrel';
import { IBitfApiCallState } from '@interfaces';

export const apiCallStateConfig = [
  {
    apiCallStateName: EApiCallStateNames.DASHBOARD_DIARY,
    apiRequest: {
      size: 1,
      embed: ['meals', 'activities($expand=activity)', 'measures'],
    },
    requestParts: [new DaySelectorApiRequestPart()],
    apiCallStateMapper: new DefaultApiCallStateMapper(),
  } as IBitfApiCallState,
];
