<div class="aboca-fixed-viewport d-flex flex-column justify-content-center align-items-center">
  <img src="assets/img/full-screen-dialog/error.png" />
  <h1>{{ 'COMMON.NOT_FOUND_PAGE.ERROR' | translate }}</h1>
  <p>{{ 'COMMON.NOT_FOUND_PAGE.MESSAGE' | translate }}</p>
  <a mat-flat-button
    color="accent"
    class="w-100"
    [routerLink]="['/']">
    {{ 'COMMON.NOT_FOUND_PAGE.BACK_BUTTON' | translate }}
  </a>
</div>