import { Injectable } from '@angular/core';

import { BitfStorageService } from '@bitf/services/storage/bitf-storage.service';
import { AbocaStorage } from '@models';

@Injectable({
  providedIn: 'root',
})
export class StorageService extends BitfStorageService<AbocaStorage> {
  constructor() {
    super({
      nativeStorage: localStorage,
      storageKey: 'storage',
      StorageClass: AbocaStorage,
    });
  }
}
