import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

// tslint:disable-next-line:max-line-length
import { BitfMatToastMessageService } from '@bitf/services/toast-messages/material/bitf-mat-toast-message.service';
import { IBitfToastMessagesService } from '@interfaces';

@Injectable({
  providedIn: 'root',
})
export class ToastMessagesService extends BitfMatToastMessageService implements IBitfToastMessagesService {
  constructor(protected matSnackBar: MatSnackBar) {
    super(matSnackBar);
  }
}
