import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

import { FavoritesService } from '@services';
import { nullOrNaN } from '@bitf/utils/bitf-numbers.utils';

@Component({
  selector: 'aboca-quantity-row',
  templateUrl: './quantity-row.component.html',
  styleUrls: ['./quantity-row.component.scss'],
})
export class QuantityRowComponent implements OnInit, OnDestroy {
  @Input()
  kiloCalories: number;
  @Input()
  referenceQuantity = 100;
  @Input()
  quantity = 100;
  @Input()
  units: string;
  @Input()
  disabled = false;
  @Input()
  favoriteId: string;
  @Output()
  quantityChange = new EventEmitter<number>();

  form: FormGroup;
  quantityControl: FormControl;
  multiplier = 1;
  hasKiloCalories: boolean;
  isFavorite = false;

  private subscription = new Subscription();

  constructor(private favoriteService: FavoritesService) {}

  ngOnInit() {
    this.hasKiloCalories = !nullOrNaN(this.kiloCalories);
    this.quantityControl = new FormControl(this.quantity, Validators.required);
    this.multiplier = this.quantityControl.value / this.referenceQuantity;
    this.subscription.add(
      this.quantityControl.valueChanges.subscribe(() => {
        if (this.quantityControl.value >= 0) {
          this.multiplier = this.quantityControl.value / this.referenceQuantity;
          this.quantityChange.emit(this.quantityControl.value);
          this.form.get('disabledQuantity').setValue(this.quantityControl.value);
        } else {
          this.quantityControl.setValue(0);
        }
      })
    );
    this.form = new FormGroup({
      quantity: this.quantityControl,
      disabledQuantity: new FormControl({ value: this.quantity, disabled: this.disabled }),
    });

    if (this.favoriteId !== undefined) {
      this.isFavorite = this.favoriteService.hasFavorite(this.favoriteId);
      this.subscription.add(
        this.favoriteService.favouritesChanged$.subscribe(
          () => (this.isFavorite = this.favoriteService.hasFavorite(this.favoriteId))
        )
      );
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onFavorite() {
    const wasFavorite = this.favoriteService.hasFavorite(this.favoriteId);
    this.isFavorite = !wasFavorite;
    if (wasFavorite) {
      this.favoriteService.removeFavorite(this.favoriteId);
    } else {
      this.favoriteService.addFavorite(this.favoriteId);
    }
  }
}
