import { Component, OnInit, Optional, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DiaryActivity } from '@models';
import { DialogsService, StoreService } from '@services';
import { EBitfCloseEventStatus } from '@enums';
import { IBitfCloseEvent } from '@interfaces';
import { CONSTANTS } from '@constants';
import { filter, tap } from 'rxjs/operators';
import { BitfMatSidenavService } from '@bitf/core/services/sidenav/material/bitf-mat-sidenav.service';
import { bitfToTranslate } from '@bitf/utils/bitf-translate.utils';

@Component({
  selector: 'aboca-activity-edit',
  templateUrl: './activity-edit.component.html',
  styleUrls: ['./activity-edit.component.scss'],
})
export class ActivityEditComponent implements OnInit {
  activity: DiaryActivity;
  kiloCalories: number;

  private duration: number;

  constructor(
    private storeService: StoreService,
    private bitfMatSidenavService: BitfMatSidenavService,
    @Optional() private dialogRef: MatDialogRef<ActivityEditComponent>,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    data: { activity?: DiaryActivity },
    private dialogsService: DialogsService
  ) {
    this.activity = (data || {}).activity;
  }

  ngOnInit() {
    this.kiloCalories = this.activity.getReferenceKCalories(this.storeService.store.user.userData.weight);
    this.duration = this.activity.duration;
  }

  onDurationChange(duration: number) {
    this.duration = duration;
  }

  onDelete() {
    this.dialogsService.dialog
      .open(CONSTANTS.okCancelDialogComponent, {
        data: {
          title: bitfToTranslate('DELETE.DIALOG.TITLE'),
          message: bitfToTranslate('DELETE.DIALOG.TEXT'),
          okText: bitfToTranslate('COMMON.OK'),
          cancelText: bitfToTranslate('COMMON.CANCEL'),
        },
      })
      .afterClosed()
      .pipe(
        filter(
          (dialogRes: IBitfCloseEvent<void>) => dialogRes && dialogRes.status === EBitfCloseEventStatus.OK
        ),
        tap(() => this.onClose(true))
      )
      .subscribe();
  }

  onClose(deleteActivity = false) {
    const changed = deleteActivity || this.duration !== this.activity.duration;
    const data = { activity: this.activity, duration: this.duration, deleteActivity, changed };
    if (this.dialogRef) {
      this.dialogRef.close({
        status: EBitfCloseEventStatus.OK,
        data,
      });
    } else {
      this.bitfMatSidenavService.close({
        status: EBitfCloseEventStatus.CLOSE,
        data,
      });
    }
  }
}
