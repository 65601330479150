import { SuperModel } from './super-model.model';
import { UserData } from './user-data.model';

export class User extends SuperModel {
  firstName: string;
  lastName: string;
  email: string;
  telephone: string;
  isActive: boolean;
  registrationDate: Date;
  district: string;
  state: EUserState;
  hasUpdatedWeight: boolean;

  userData?: UserData;

  constructor(data: any = {}) {
    super(data);
    if (data.userData) {
      this.userData = new UserData(data.userData);
    }
  }

  get serialised() {
    return {
      id: this.id,
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      telephone: this.telephone,
      isActive: this.isActive,
      registrationDate: this.registrationDate,
      district: this.district,
      state: this.state,
      hasUpdatedWeight: this.hasUpdatedWeight,
      userData: this.userData && this.userData.serialised,
    };
  }
}

export enum EUserState {
  None = 'None',
  ProfileOnly = 'ProfileOnly',
  ProfileAndData = 'ProfileAndData',
  ProfileDataAndDiet = 'ProfileDataAndDiet',
}
