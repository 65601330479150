import { Injectable, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Observable, isObservable, from, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { EUserState } from '@enums';
import { BITF_CONFIGS } from '@configs';
import { BitfAuthGuard } from '@common/libs/bitforce/core/services/guards/bitf-auth.guard';

const { getRouteFromUserState } = BITF_CONFIGS;

const userStateLevelMap = {
  [EUserState.None]: 0,
  [EUserState.ProfileOnly]: 0,
  [EUserState.ProfileAndData]: 2,
  [EUserState.ProfileDataAndDiet]: 3,
};

@Injectable({
  providedIn: 'root',
})
export class AuthGuard extends BitfAuthGuard {
  constructor(injector: Injector) {
    super(injector);
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const result = super.canActivate(next, state);
    let observable$: Observable<boolean>;
    if (isObservable(result)) {
      observable$ = result;
    } else if (result instanceof Promise) {
      observable$ = from(result);
    } else {
      observable$ = of(result);
    }
    return observable$.pipe(
      map((canActivate: boolean) => {
        if (canActivate) {
          const user = this.storeService.store.user;
          const requiredLevel = userStateLevelMap[next.data.userState];
          const userStateLevel = userStateLevelMap[user.state];
          canActivate = requiredLevel <= userStateLevel;
          if (!canActivate) {
            this.router.navigate([getRouteFromUserState(user.state)]);
          }
        }

        return canActivate;
      })
    );
  }
}
