// tslint:disable-next-line:max-line-length
import { EBitfParsers } from '@enums';
import { BitfODataRequestMapper } from '@bitf/core/parsers/odata-parser';

export class AppRequestMapper extends BitfODataRequestMapper {
  name = EBitfParsers.BITF_APP_PARSER;
  constructor() {
    super();
  }
}
