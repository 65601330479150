<mat-sidenav-container>
    <mat-sidenav #sidenav>
        <ng-container #sidenavContent></ng-container>
    </mat-sidenav>
    <mat-sidenav-content class="aboca-fixed-viewport aboca-scroll-y d-flex flex-column"
        [class.aboca-content-without-header]="!showHeader">
        <aboca-header *ngIf="showHeader"
            class="fixed-top"></aboca-header>
        <div class="container-fluid mt-2 tabs"
            *ngIf="showNavbar">
            <div class="row">
                <div class="col px-3"
                    *ngFor="let section of sections">
                    <a mat-flat-button
                        matBadge
                        [abocaMenuItemBadge]='section.route'
                        [routerLink]="section.url"
                        routerLinkActive="nav-button-selected"
                        class="w-100 nav-button">{{section.label | translate}}
                    </a>
                </div>
            </div>
        </div>
        <div class="flex-grow-1">
            <router-outlet></router-outlet>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>