import { Component, OnInit, Optional, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup } from '@angular/forms';

import { Measure } from '@models';
import { EBitfCloseEventStatus } from '@enums';
import { BitfMatSidenavService } from '@bitf/core/services/sidenav/material/bitf-mat-sidenav.service';

@Component({
  selector: 'aboca-glycemia-edit',
  templateUrl: './glycemia-edit.component.html',
  styleUrls: ['./glycemia-edit.component.scss'],
})
export class GlycemiaEditComponent implements OnInit {
  measure: Measure;
  valueControl: FormControl;
  form: FormGroup;

  constructor(
    private bitfMatSidenavService: BitfMatSidenavService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    data: { measure: Measure },
    @Optional() private dialogRef: MatDialogRef<GlycemiaEditComponent>
  ) {
    const { measure } = data || { measure: undefined };
    this.measure = measure;
  }

  ngOnInit() {
    this.valueControl = new FormControl(this.measure.value);
    this.form = new FormGroup({
      quantity: this.valueControl,
    });
  }

  onCancel() {
    this.close();
  }

  onClose() {
    this.close(this.valueControl.value);
  }

  private close(data?: number) {
    if (this.dialogRef) {
      this.dialogRef.close({
        status: EBitfCloseEventStatus.OK,
        data,
      });
    } else {
      this.bitfMatSidenavService.close({
        status: EBitfCloseEventStatus.CLOSE,
        data,
      });
    }
  }
}
