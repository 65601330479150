import { Injectable } from '@angular/core';
import { UserData } from '../models';
import { StorageService } from '@common/core/services';

@Injectable({
  providedIn: 'root',
})
export class AbocaStorageService {
  constructor(private storageService: StorageService) {}

  setUserData(userData: UserData) {
    this.storageService.data = { userData: userData.serialised };
  }

  getUserData() {
    return this.storageService.data.userData ? new UserData(this.storageService.data.userData) : null;
  }

  clearUserData() {
    this.storageService.data = { userData: null };
  }
}
