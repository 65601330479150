<div class="py-1 row align-items-baseline">
  <div class="col">
    <form [formGroup]="form">
      <mat-form-field *ngIf="!disabled"
        appearance="fill"
        color="primary">
        <mat-label>{{units | translate}}</mat-label>
        <input matInput
          [formControl]="quantityControl"
          min="0"
          type="number">
      </mat-form-field>
      <mat-form-field *ngIf="disabled"
        appearance="fill"
        color="primary">
        <mat-label>{{units | translate}}</mat-label>
        <input matInput
          formControlName="disabledQuantity"
          type="number">
      </mat-form-field>
    </form>
  </div>

  <h2 class="col text-left"
    *ngIf="hasKiloCalories">
    {{kiloCalories * multiplier | number:'1.0-0'}} {{'UNITS.KCAL' | translate}}
  </h2>

  <div class="col-4 text-right aboca-primary-color"
    *ngIf="favoriteId !== undefined">
    <button mat-icon-button
      (click)="onFavorite()">
      <mat-icon *ngIf="isFavorite">favorite</mat-icon>
      <mat-icon *ngIf="!isFavorite">favorite_border</mat-icon>
    </button>
  </div>
</div>