export enum EUserDefaultRoutes {
  WIZARD = 'wizard',
  WEIGHT_TARGET = 'peso-obiettivo',
  DASHBOARD = 'dashboard',
  SIGNIN = 'signin',
  PLANNING = 'pianificazione',
  INFO = 'info',
  MY_PROGRESS = 'progressi',
  FAVORITES = 'preferiti',
}
