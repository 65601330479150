import { Component, OnInit, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ECombinationType, DiaryTimeTranslationMap } from '@models';
import { EBitfCloseEventStatus } from '@web/enums';

@Component({
  selector: 'aboca-copy-to-diary-dialog',
  templateUrl: './copy-to-diary-dialog.component.html',
  styleUrls: ['./copy-to-diary-dialog.component.scss'],
})
export class CopyToDiaryDialogComponent implements OnInit {
  selectedDate: Date;
  combinationTypeId: ECombinationType;
  form: FormGroup;
  translations = Object.entries(DiaryTimeTranslationMap).map(e => ({
    key: parseFloat(e[0]),
    value: e[1],
  }));

  constructor(
    @Optional() private dialogRef: MatDialogRef<CopyToDiaryDialogComponent>,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    data: { selectedDate?: Date },
    private formBuilder: FormBuilder
  ) {
    data = data || {};
    this.selectedDate = data.selectedDate;
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      selectedDate: [this.selectedDate, Validators.required],
      diaryTimeId: [ECombinationType.MY_BREAKFASTS, Validators.required],
    });
  }

  onClose(addToDiary: boolean = false) {
    this.dialogRef.close({
      status: addToDiary ? EBitfCloseEventStatus.OK : EBitfCloseEventStatus.CANCEL,
      data: { date: this.form.get('selectedDate').value, diaryTimeId: this.form.get('diaryTimeId').value },
    });
  }
}
