import { AppRequestMapper, AppResponseMapper, AppPathBuilder, AppEnvelopeMapper } from '@common/parsers';

import { IBitfParserStrategy } from '@interfaces';
import {
  BitfMockDbPathBuilder,
  BitfMockDbRequestMapper,
  BitfMockDbResponseMapper,
  BitfMockDbEnvelopeMapper,
} from '@bitf/core/parsers/mock-db-parser';

export const BITF_CONFIGS: any = {
  apiErrorsInterceptorService: {
    interceptHttpResponsesWithCode: ['0', '401', '4XX', '5XX'],
  },
  sockets: { eventName: 'asyncEvents', hubName: 'jobsHub' },
  authService: {
    tokenAlertTresholdMinutes: 5,
    renewToken: {
      tokenHeaderKey: 'X-Apoteca-Jwt',
    },
  },
  parsers: {
    defaultParser: 'appParser',
    parserStrategies: {
      appParser: {
        namespace: '@sintra',
        uiMessageKey: 'messages',
        customHeaders: {
          Prefer: 'odata.include-annotations=*',
        },
        pathBuilder: new AppPathBuilder(),
        requestMapper: new AppRequestMapper(),
        responseMapper: new AppResponseMapper(),
        envelopeMapper: new AppEnvelopeMapper(),
      } as IBitfParserStrategy,
      mockDbParser: {
        pathBuilder: new BitfMockDbPathBuilder(),
        requestMapper: new BitfMockDbRequestMapper(),
        responseMapper: new BitfMockDbResponseMapper(),
        envelopeMapper: new BitfMockDbEnvelopeMapper(),
      } as IBitfParserStrategy,
    },
  },
};
