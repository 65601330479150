import { SuperModel } from './super-model.model';

export class Measure extends SuperModel {
  diaryId: number;
  diaryTimeId: number;
  measureTypeId: number;
  timestamp: Date;
  value: number;

  constructor(object: any) {
    super(object);
    this.timestamp = this.timestamp && new Date(this.timestamp);
  }

  get serialised() {
    return {
      id: this.id,
      diaryId: this.diaryId,
      diaryTimeId: this.diaryTimeId,
      measureTypeId: this.measureTypeId,
      timestamp: this.timestamp,
      value: this.value,
    };
  }
}

export enum EMeasures {
  WEIGHT = 1,
  GLYCEMIA = 2,
}
