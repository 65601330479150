<div class="d-flex flex-column h-100">
  <mat-toolbar class="aboca-toolbar">
    <mat-toolbar-row>
      <aboca-header-close title="Glicemia"
        class="flex-grow-1"
        (close)="onCancel()"></aboca-header-close>
    </mat-toolbar-row>
  </mat-toolbar>

  <div class="flex-grow-1 flex-shrink-1 container-fluid aboca-scroll-y my-2">
    <form [formGroup]="form"
      (submit)="onClose()">
      <mat-form-field appearance="fill"
        color="primary">
        <mat-label>mg/dl</mat-label>
        <input matInput
          [formControl]="valueControl"
          type="number">
      </mat-form-field>

    </form>
  </div>

  <div class="container-fluid flex-shrink-0 flex-grow-0 border-top">
    <div class="row">

      <div class="col d-flex flex-row flex-grow-1">
        <button mat-stroked-button
          class="m-1 col"
          (click)="onCancel()"
          color="">{{'COMMON.CANCEL' | translate}}</button>

        <button mat-flat-button
          class="m-1 col"
          (click)="onClose()"
          color="primary">{{'COMMON.APPLY' | translate}}</button>
      </div>
    </div>
  </div>
</div>