<form [formGroup]="form"
  (submit)="onSubmit($event)">
  <mat-form-field appearance="fill"
    class="aboca-square-field">
    <input matInput
      #searchInput
      placeholder="{{'SEARCH.SEARCH_A_FOOD' | translate}}"
      formControlName="search">
    <mat-icon matSuffix
      class="aboca-grey-color">search</mat-icon>
  </mat-form-field>
</form>