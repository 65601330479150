import { SuperModel } from './super-model.model';

export class DietType {
  id: number;
  calories: number;

  constructor(data: any) {
    this.id = data.id;
    this.calories = data.calories;
  }
}
