import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'aboca-header-close',
  templateUrl: './header-close.component.html',
  styleUrls: ['./header-close.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderCloseComponent implements OnInit {
  @Input()
  title: string;

  @Output()
  close = new EventEmitter<void>();

  constructor() {}

  ngOnInit() {}
}
