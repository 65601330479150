import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { nullOrNaN } from '@bitf/utils/bitf-numbers.utils';

@Component({
  selector: 'aboca-food-nutrients-row',
  templateUrl: './food-nutrients-row.component.html',
  styleUrls: ['./food-nutrients-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FoodNutrientsRowComponent {
  @Input()
  name: string;
  @Input()
  value: number;
  @Input()
  unit: string;
  @Input()
  type?: string;

  nullOrNaN(value: number) {
    return nullOrNaN(value);
  }
}
