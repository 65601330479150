import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class BitfTagManagerService {
  init() {
    window['dataLayer'] = window['dataLayer'] || [];
    const tagManagerConf = environment['tagManager'];
    if (this.isConfiguredCorrectlyAndEnabled()) {
      this.injectScripts(tagManagerConf.apiKey);
    } else if (environment.production) {
      window['dataLayer'].push = () => undefined;
    }
  }

  private isConfiguredCorrectlyAndEnabled() {
    const tagManagerConf = environment['tagManager'];
    if (tagManagerConf) {
      if (tagManagerConf.enabled === undefined) {
        console.warn('Cannot find tagManager.enabled property');
        return false;
      }
      if (tagManagerConf.enabled) {
        if (tagManagerConf.apiKey === undefined) {
          console.warn('Cannot find tagManager.apiKey property');
          return false;
        }
      }
    }

    return tagManagerConf != null && tagManagerConf.enabled;
  }

  get dataLayer() {
    return window['dataLayer'];
  }

  private injectScripts(apiKey: string) {
    document.head.appendChild(this.createScript(TAG_MANAGER_DATA_LAYER(apiKey)));
  }

  private createScript(text: string) {
    const script = document.createElement('script');
    script.text = text;
    return script;
  }
}

const TAG_MANAGER_DATA_LAYER = (apiKey: string) => `
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${apiKey}');
`;
