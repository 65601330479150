<div class="d-flex flex-column h-100">
  <mat-toolbar class="aboca-toolbar flex-shrink-0">
    <aboca-header-back [title]="searchResult.name"
      class="flex-grow-1"
      (back)="back.emit()"></aboca-header-back>
  </mat-toolbar>

  <div class="flex-grow-1 container-fluid aboca-scroll-y">
    <aboca-quantity-row [kiloCalories]="kiloCalories"
      [units]="units"
      [quantity]="initialQuantity"
      [favoriteId]="favoriteId"
      (quantityChange)="onQuantityChange($event)"
      (submit)="onAdd()">
    </aboca-quantity-row>

    <aboca-food-nutrients-table *ngIf="hasNutrients"
      [nutritionData]="searchResult.nutrients"
      [subHeader]=""
      [quantity]="quantity"></aboca-food-nutrients-table>
  </div>

  <div class="flex-shrink-0 p-3 d-flex border-top">
    <button mat-flat-button
      (click)="onAdd()"
      color="primary"
      class="flex-grow-1">{{'COMMON.ADD' | translate}}</button>
  </div>
</div>