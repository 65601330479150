import { SuperModel } from './super-model.model';
import { SearchFoodResult } from './serach-food-result.model';
import { Food, NutritionData } from './food.model';
import { bitfToTranslate } from '@bitf/utils/bitf-translate.utils';

export class MyMeal extends SuperModel {
  userId: string;
  label: string;
  notes: string;
  combinationTypeId: ECombinationType;
  nutrients: NutritionData;

  records?: MyMealRecord[];

  constructor(data: any) {
    super(data);
    if (this.records) {
      this.records = this.records.map(r => new MyMealRecord(r));
    } else {
      this.records = [];
    }
    if (this.nutrients) {
      this.nutrients = new NutritionData(this.nutrients);
    }
  }

  getComputedNutritionData(): NutritionData {
    return new NutritionData(
      this.records.reduce((accumulator, record) => {
        const nutrients = record.product.nutrients;
        const newValue = {};
        for (const key in nutrients) {
          if (nutrients.hasOwnProperty(key)) {
            newValue[key] = (accumulator[key] || 0) + (nutrients[key] || 0);
          }
        }
        return newValue;
      }, {})
    );
  }

  getCombinationLabel() {
    return CombinationTypeTranslationMap[this.combinationTypeId];
  }

  get serialised() {
    return {
      id: this.id,
      userId: this.userId,
      label: this.label,
      notes: this.notes,
      combinationTypeId: this.combinationTypeId,
      nutrients: this.nutrients,
      records: this.records && this.records.map(r => r.serialised),
    };
  }
}

export class MyMealRecord extends SuperModel {
  userMealId: string;
  productId: string;
  quantity: number;

  product?: SearchFoodResult;

  constructor(data: any) {
    super(data);
    if (this.product) {
      this.product = new SearchFoodResult(this.product);
      this.product.setQuantity(this.quantity);
    }
  }

  setQuantity(quantity: number) {
    this.quantity = quantity;
    this.product.setQuantity(this.quantity);
  }

  toFood() {
    const data = Object.assign(
      { quantity: this.quantity, unit: 'g', foodProductId: this.productId, foodName: this.product.name },
      this.product.nutrients
    );

    return new Food(data);
  }

  get serialised() {
    return {
      id: this.id,
      userMealId: this.userMealId,
      productId: this.productId,
      quantity: this.quantity,
      product: this.product && this.product.serialised,
    };
  }
}

export enum ECombinationType {
  MY_RECIPES = 7,
  MY_BREAKFASTS = 1,
  MY_LUNCHES = 3,
  MY_SNACKS = 2,
  MY_DINNERS = 5,
  MY_AFTERNOON_SNACKS = 4,
  OTHER_MEALS = 6,
}

export const CombinationTypeTranslationMap = {
  [ECombinationType.MY_RECIPES]: bitfToTranslate('FAVORITES.MY_RECIPES'),
  [ECombinationType.MY_BREAKFASTS]: bitfToTranslate('FAVORITES.EDIT_MEAL.MEAL_COMBINATION.MY_BREAKFASTS'),
  [ECombinationType.MY_LUNCHES]: bitfToTranslate('FAVORITES.EDIT_MEAL.MEAL_COMBINATION.MY_LUNCHES'),
  [ECombinationType.MY_SNACKS]: bitfToTranslate('FAVORITES.EDIT_MEAL.MEAL_COMBINATION.MY_SNACKS'),
  [ECombinationType.MY_DINNERS]: bitfToTranslate('FAVORITES.EDIT_MEAL.MEAL_COMBINATION.MY_DINNERS'),
  [ECombinationType.MY_AFTERNOON_SNACKS]: bitfToTranslate(
    'FAVORITES.EDIT_MEAL.MEAL_COMBINATION.MY_AFTERNOON_SNACKS'
  ),
  [ECombinationType.OTHER_MEALS]: bitfToTranslate('FAVORITES.EDIT_MEAL.MEAL_COMBINATION.OTHER_MEALS'),
};
