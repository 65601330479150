import { Component, ChangeDetectionStrategy } from '@angular/core';

import { NAVBAR_ELEMENTS } from '../../common/navbar-elements';

@Component({
  selector: 'aboca-mobile-navbar',
  templateUrl: './mobile-navbar.component.html',
  styleUrls: ['./mobile-navbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileNavBarComponent {
  navbarElements = NAVBAR_ELEMENTS;

  constructor() {}
}
