import { SuperModel } from './super-model.model';
import { ENeeds, EEatingHabits } from './user-data.model';
import { NutritionData, Food } from './food.model';
import { SearchFoodResult } from './serach-food-result.model';

export class SuggestedMeal extends SuperModel {
  id: string;
  dietTypeId: number;
  needId: ENeeds;
  eatingHabitId: EEatingHabits;
  name: string;
  nutrients: NutritionData;
  records: SuggestedMealRecord[];

  constructor(data: any) {
    super(data);
    this.nutrients = this.nutrients && new NutritionData(this.nutrients);
    this.records = (this.records || []).map(e => new SuggestedMealRecord(e));
  }

  get label() {
    return this.name;
  }

  getComputedNutritionData() {
    return this.nutrients;
  }

  get serialised() {
    return {};
  }
}

export class SuggestedMealRecord extends SuperModel {
  suggestedMealId: number;
  productId: number;
  quantity: number;
  product: SearchFoodResult;

  constructor(data: any) {
    super(data);

    this.product = this.product && new SearchFoodResult(this.product);
  }

  toFood() {
    const data = Object.assign(this.product.nutrients, {
      quantity: this.quantity,
      unit: 'g',
      foodProductId: this.productId,
      foodName: this.product.name,
    });

    return new Food(data);
  }

  get serialised() {
    return {
      id: this.id,
      userMealId: this.suggestedMealId,
      productId: this.productId,
      quantity: this.quantity,
      product: this.product && this.product.serialised,
    };
  }
}
