import { Injectable } from '@angular/core';
import { Subject, of, Observable } from 'rxjs';
import { debounceTime, finalize, tap, catchError, map, mergeMap, filter } from 'rxjs/operators';

import { UsersService } from './api';
import { StoreService } from './store.service';

import { IBitfApiResponse } from '@interfaces';
import { Favorite } from '@models';

interface Action {
  productId: string;
  delete: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class FavoritesService {
  readonly favouritesChanged$ = new Subject<void>();

  private favoriteSet = new Set<string>();
  private addOrDelete$ = new Subject<Action>();

  constructor(private usersService: UsersService, private storeService: StoreService) {
    this.addOrDelete$
      .pipe(
        debounceTime(500),
        mergeMap((action: Action) => {
          let ob$: Observable<any>;
          if (action.delete) {
            ob$ = usersService.delete({
              id: storeService.store.user.id,
              relation: 'favoriteFoods',
              relationId: action.productId,
            });
          } else {
            ob$ = usersService.post<Favorite>({
              id: storeService.store.user.id,
              relation: 'favoriteFoods',
              isBodyRaw: true,
              body: {
                productId: action.productId,
              },
            });
          }

          return ob$.pipe(
            map(() => ({ action, error: null })),
            catchError(error => of({ action, error }))
          );
        })
      )
      .subscribe(({ action, error }) => {
        if (!error) {
          const method = action.delete ? 'delete' : 'add';
          this.favoriteSet[method](action.productId);
        }

        this.favouritesChanged$.next();
      });
  }

  loadFavoriteFoods() {
    return this.usersService
      .get<Favorite>({
        id: this.storeService.store.user.id,
        relation: 'favoriteFoods',
        disableHideLoader: true,
      })
      .pipe(
        tap((response: IBitfApiResponse<Favorite[]>) => {
          this.favoriteSet = new Set<string>(response.content.map(m => m.productId));
        })
      );
  }

  addFavorite(productId: string) {
    this.addOrDelete$.next({ delete: false, productId });
  }

  removeFavorite(productId: string) {
    this.addOrDelete$.next({ delete: true, productId });
  }

  hasFavorite(productId: string): boolean {
    return this.favoriteSet.has(productId);
  }
}
