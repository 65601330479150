import { OnInit, Injector } from '@angular/core';

import { EBitfCloseEventStatus } from '@enums';
import { User } from '@models';
import { StoreService } from '@services';
import { BitfMatSidenavService } from '@bitf/core/services/sidenav/material/bitf-mat-sidenav.service';

export abstract class CommonMenuComponent implements OnInit {
  user: User;

  mSidenavService: BitfMatSidenavService;

  constructor(public injector: Injector) {
    this.mSidenavService = this.injector.get(BitfMatSidenavService);
    this.user = injector.get(StoreService).store.user;
  }

  ngOnInit() {}

  closeSidenav() {
    this.mSidenavService.close({ status: EBitfCloseEventStatus.CLOSE });
  }
}
