<div class="d-flex flex-column h-100"
  [hidden]="selectedFood">
  <mat-toolbar class="aboca-toolbar">
    <mat-toolbar-row>
      <aboca-header-close [title]="meal.title"
        class="flex-grow-1"
        (close)="onClose()"></aboca-header-close>
    </mat-toolbar-row>
  </mat-toolbar>

  <div class="flex-grow-1 aboca-scroll-y">

    <div class="container-fluid py-3">
      <div class="border-bottom border-secondary mat-h2 mb-0">{{'MEAL_DETAILS.FOOD_COMPOSITION' |
        translate}}</div>
      <mat-nav-list>
        <mat-list-item *ngFor="let food of meal.records; let i = index;"
          (click)="onFoodClick(i)"
          class="border-bottom">
          <aboca-item-row [item]="food"
            [column]="true"
            [textTruncate]="true"
            class="flex-with-truncate-child"></aboca-item-row>
          <mat-icon matListIcon
            class="aboca-grey-color">arrow_forward_ios</mat-icon>
        </mat-list-item>
      </mat-nav-list>
    </div>

    <mat-divider></mat-divider>
    <div class="container-fluid py-3">
      <aboca-food-nutrients-table [nutritionData]="meal.getNutritionData()"></aboca-food-nutrients-table>
    </div>

    <mat-divider></mat-divider>

    <div class="container-fluid py-3 mat-body-strong">
      <div class="d-flex justify-content-between">
        <span>Calorie totali</span><span>{{totalKiloCalories | number:'1.0-0'}}
          {{'UNITS.KCAL' | translate}}</span>
      </div>
    </div>
  </div>

</div>

<aboca-food-edit *ngIf="selectedFood"
  [food]="selectedFood"
  [closeButton]="onlyFoodDetails"
  [readonly]="meal.state === ERecordState.CONFIRMED"
  (back)="onBack()"
  (close)="onClose()"
  (apply)="onApply($event)"
  (delete)="onDelete()"></aboca-food-edit>