<span class="mat-h3 mb-0 text-truncate">{{item.name}}<span *ngIf="item.brand"> -
    {{item.brand}}</span></span>
<div>
  <span class="mat-small"
    *ngIf="item.manufacter">{{item.manufacter.name}} - </span>
  <span class="mat-small"
    *ngIf="item.nutrients">{{item.nutrients.kiloCalories | number:'1.0-0'}}
    {{'UNITS.KCAL' | translate}} - {{item.quantity | number:'1.0-0'}}g</span>
  <span class="mat-small"
    *ngIf="item.getKCalories">{{item.getKCalories(weight, 60) | number:'1.0-0'}}
    {{'UNITS.KCAL' | translate}} - 1h</span>
</div>