import { BitfODataEnvelopeMapper } from '@bitf/core/parsers/odata-parser';
import { IBitfEnvelopeMapperData, IBitfApiResponse } from '@interfaces';
import { HttpResponse } from '@angular/common/http';
import { EBitfUiMessageType, EBitfUiMessageTarget } from '@web/enums';

export class AppEnvelopeMapper extends BitfODataEnvelopeMapper {
  map(data: IBitfEnvelopeMapperData): HttpResponse<IBitfApiResponse<any>> {
    const result = super.map(data);
    const body: any = result.body.originalBody;
    if (body && body.error && result.body.metadata.uiMessages.length === 0) {
      result.body.metadata.uiMessages = [
        {
          title: 'Error',
          message: body.error.code + ' ' + body.error.message,
          code: body.error.code,
          type: EBitfUiMessageType.ERROR,
          target: EBitfUiMessageTarget.TOAST,
        },
      ];
    }

    return result;
  }
}
