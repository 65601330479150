import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SharedModule } from '@shared/shared.module';

import { SignInComponent } from './sign-in/sign-in.component';

const NG_COMPONENTS = [SignInComponent];

@NgModule({
  imports: [SharedModule, RouterModule],
  declarations: [...NG_COMPONENTS],
})
export class LoginModule {}
