import { NgModule, Optional, SkipSelf } from '@angular/core';
import { RouterModule } from '@angular/router';

import { BitfRedirectComponent } from '@bitf/core/components/functional/redirect';

import { SharedModule } from '@shared/shared.module';
import { CommonCoreModule } from '@common/core/common-core.module';

import { throwIfAlreadyLoaded } from './module-config';
import { HeaderComponent } from './components/header/header.component';
import { MenuComponent } from './components/menu/menu.component';
import { MobileLayoutComponent } from './components/mobile-layout/mobile-layout.component';
import { DesktopLayoutComponent } from './components/desktop-layout/desktop-layout.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { MobileNavBarComponent } from './components/mobile-layout/mobile-navbar/mobile-navbar.component';

// tslint:disable-next-line:max-line-length
import { ApotecaDesktopFakeContainerComponent } from './components/desktop-layout/apoteca-desktop-fake-container/apoteca-desktop-fake-container.component';
// tslint:disable-next-line:max-line-length
import { DesktopLayoutContentComponent } from './components/desktop-layout/desktop-layout-content/desktop-layout-content.component';
import { LoginModule } from '../modules/login/login.module';

const EXPORTS_COMPONENTS = [NotFoundComponent];
const COMPONENTS = [
  MenuComponent,
  BitfRedirectComponent,
  MobileLayoutComponent,
  DesktopLayoutComponent,
  HeaderComponent,
  MobileNavBarComponent,
  ApotecaDesktopFakeContainerComponent,
  DesktopLayoutContentComponent,
];
const ENTRY_COMPONENTS = [MenuComponent];

@NgModule({
  imports: [CommonCoreModule, RouterModule, SharedModule.forRoot(), LoginModule],
  exports: [...EXPORTS_COMPONENTS],
  declarations: [...EXPORTS_COMPONENTS, ...COMPONENTS],
  entryComponents: [...ENTRY_COMPONENTS],
  providers: [],
})
export class CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule
  ) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
