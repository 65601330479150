import { Injectable } from '@angular/core';
import { StoreService } from '@common/core/services';
import { EStoreActions } from '@enums';
import { Store } from '@models';

@Injectable({
  providedIn: 'root',
})
export class InAppService {
  constructor(private storeService: StoreService) {}

  isApotecaNaturaWebView() {
    return window.navigator.userAgent.includes('MyApotecaNatura');
  }

  startBarCodeScanner() {
    if (this.isApotecaNaturaWebView()) {
      window.location.href = 'https://guidaalimentare.apotecanatura.it/barcode-scanner';
    }
  }

  processNativeEvent(event: string, payload: any) {
    if (event === 'barcode') {
      this.storeService.setStore((store: Store) => {
        store.barcode = payload;
      }, EStoreActions.BAR_CODE_RECEIVED);
    }
  }
}
