import { Component, OnInit, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { switchMap, filter, tap } from 'rxjs/operators';

import { Meal, Food } from '@models';
import { DailyDiaryService, DialogsService, LoaderService } from '@services';
import { EBitfCloseEventStatus, ERecordState } from '@enums';
import { CONSTANTS } from '@constants';
import { IBitfCloseEvent } from '@interfaces';
import { bitfToTranslate } from '@bitf/utils/bitf-translate.utils';
import { BitfMatSidenavService } from '@bitf/core/services/sidenav/material/bitf-mat-sidenav.service';

@Component({
  selector: 'aboca-meal-details',
  templateUrl: './meal-details.component.html',
  styleUrls: ['./meal-details.component.scss'],
})
export class MealDetailsComponent implements OnInit {
  meal: Meal;
  dietDayId: string;
  totalKiloCalories: number;
  selectedFoodIndex: number;
  modifyState = false;
  onlyFoodDetails = false;
  dietDayNeedsReload = false;
  ERecordState = ERecordState;

  constructor(
    private bitfMatSidenavService: BitfMatSidenavService,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    data: { meal: Meal; dietDayId: string; selectedFoodIndex: number },
    @Optional() private dialogRef: MatDialogRef<MealDetailsComponent>,
    private loaderService: LoaderService,
    private dailyDiaryService: DailyDiaryService,
    private dialogsService: DialogsService
  ) {
    const { meal, dietDayId, selectedFoodIndex } =
      data || <{ meal: Meal; dietDayId: string; selectedFoodIndex: number }>{};
    this.meal = meal;
    this.dietDayId = dietDayId;
    this.selectedFoodIndex = selectedFoodIndex;
  }

  ngOnInit() {
    this.onlyFoodDetails = this.selectedFoodIndex !== undefined;
    this.totalKiloCalories = this.meal.getTotalKCalories();
  }

  get selectedFood() {
    return this.meal.records[this.selectedFoodIndex];
  }

  onClose() {
    const data = { reload: this.dietDayNeedsReload };
    if (this.dialogRef) {
      this.dialogRef.close({
        status: EBitfCloseEventStatus.OK,
        data,
      });
    } else {
      this.bitfMatSidenavService.close({
        status: EBitfCloseEventStatus.CLOSE,
        data,
      });
    }
  }

  onBack() {
    if (this.modifyState) {
      this.modifyState = false;
    } else {
      this.selectedFoodIndex = undefined;
    }
  }

  onFoodClick(index: number) {
    this.selectedFoodIndex = index;
  }

  onModify() {
    this.modifyState = true;
  }

  onApply(newQuantity: number) {
    this.loaderService.show();
    this.dietDayNeedsReload = true;
    this.dailyDiaryService
      .updateFood({
        dietDayId: this.dietDayId,
        mealId: this.meal.id,
        foodId: this.selectedFood.id,
        quantity: newQuantity,
      })
      .subscribe(() => {
        this.meal.records[this.selectedFoodIndex] = new Food(this.selectedFood.serialised, newQuantity);
        this.selectedFood.quantity = newQuantity;
        this.resetState();
      });
  }

  onDelete() {
    this.dialogsService.dialog
      .open(CONSTANTS.okCancelDialogComponent, {
        data: {
          title: bitfToTranslate('DELETE.DIALOG.TITLE'),
          message: bitfToTranslate('DELETE.DIALOG.TEXT'),
          okText: bitfToTranslate('COMMON.OK'),
          cancelText: bitfToTranslate('COMMON.CANCEL'),
        },
      })
      .afterClosed()
      .pipe(
        filter((result: IBitfCloseEvent<void>) => result && result.status === EBitfCloseEventStatus.OK),
        switchMap(() => {
          this.loaderService.show();
          return this.dailyDiaryService.deleteFood({
            dietDayId: this.dietDayId,
            mealId: this.meal.id,
            foodId: this.selectedFood.id,
          });
        }),
        tap(() => {
          this.meal.records.splice(this.selectedFoodIndex, 1);
          this.resetState();
          this.dietDayNeedsReload = true;
          if (this.onlyFoodDetails) {
            this.onClose();
          }
        })
      )
      .subscribe();
  }

  private resetState() {
    if (!this.onlyFoodDetails) {
      this.selectedFoodIndex = undefined;
    }
    this.modifyState = false;
  }
}
