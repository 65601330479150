<div class="d-flex flex-column aboca-menu p-1 pl-2 justify-content-between h-100">
  <div class="aboca-scroll-y flex-grow-1">
    Hi, {{user?.firstName}} {{user?.lastName}}
    <button mat-flat-button
      *ngFor="let menuPosition of menuItems"
      class="w-100 text-left pl-0 mt-2"
      [routerLink]="menuPosition.url"
      routerLinkActive="active-link"
      (click)="closeSidenav()">
      <mat-icon matPrefix>{{ menuPosition.icon }}
      </mat-icon>
      <span class="pl-2">{{ menuPosition.label | translate }}</span>
    </button>
  </div>

  <div class="mat-caption">
    V: {{appVersion}} / {{buildDate}}
  </div>
</div>