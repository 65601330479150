import { Injector, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Food, DiaryActivity, PhysicalActivity, DietDay } from '@models';
import { IBitfApiResponse, IBitfApiAction, IBitfApiRequest, IBitfJsonBatchRequest } from '@interfaces';
import { ERecordState } from '@enums';
import { BitfApiService } from '@bitf/services/api/bitf-api.service';
import { AbocaBatchService, StoreService } from '@services';
import { map } from 'rxjs/operators';
import { bitfFormatDateISO } from '@bitf/utils/bitf-dates.utils';
import { bitfGetProp } from '@bitf/utils/bitf-objects.utils';

@Injectable({ providedIn: 'root' })
export class DailyDiaryService extends BitfApiService {
  constructor(
    injector: Injector,
    private abocaBatchService: AbocaBatchService,
    private storeService: StoreService
  ) {
    super('diary', injector);
  }

  insertFoodInMeal(
    data: {
      dietDayId: string;
      mealId: string;
      foodId: string;
      quantity: number;
      state: ERecordState;
    },
    options?: IBitfApiRequest
  ): Observable<IBitfApiResponse<Food>> {
    const request = Object.assign(
      {
        id: data.dietDayId,
        path: `/meals('${data.mealId}')/records`,
        isBodyRaw: true,
        modelMapper: 'food',
        body: {
          quantity: data.quantity,
          state: data.state,
          foodProductId: data.foodId,
        },
      },
      options
    );
    return this.post<Food>(request);
  }

  updateFood(data: {
    dietDayId: string;
    mealId: string;
    foodId: string;
    quantity: number;
  }): Observable<IBitfApiResponse<void>> {
    return this.patch<void>({
      id: data.dietDayId,
      path: `/meals('${data.mealId}')/records(${data.foodId})`,
      isBodyRaw: true,
      body: {
        quantity: data.quantity,
      },
    });
  }

  deleteFood(data: {
    dietDayId: string;
    mealId: string;
    foodId: string;
  }): Observable<IBitfApiResponse<IBitfApiAction>> {
    return this.delete({
      id: data.dietDayId,
      path: `/meals('${data.mealId}')/records(${data.foodId})`,
    });
  }

  insertActivity(data: { dietDayId: string; duration: number; activity: PhysicalActivity }) {
    const body = new DiaryActivity({
      activityId: data.activity.id,
      notes: null,
      duration: data.duration,
      state: ERecordState.INSERTED,
      activity: data.activity,
    });
    return this.post<DiaryActivity>({
      id: data.dietDayId,
      relation: 'activities',
      body,
      modelMapper: 'diary-activity',
    });
  }

  createDietDay(date: Date): Observable<DietDay> {
    const requests: IBitfJsonBatchRequest[] = [
      {
        id: '1',
        atomicityGroup: 'group1',
        method: 'POST',
        url: `diary`,
        body: {
          state: ERecordState.INSERTED,
          userId: this.storeService.store.user.id,
          date: bitfFormatDateISO(date),
        },
      },
      {
        atomicityGroup: 'group1',
        method: 'GET',
        dependsOn: ['1'],
        url: `$1?$expand=meals,Activities($expand=activity)`,
        rawUrl: true,
      },
    ];
    return this.abocaBatchService.jsonBatch(requests, { disableHideLoader: true }).pipe(
      map(response => {
        const dietDayData = bitfGetProp(response.content, 'data', '1', 'body');
        return dietDayData ? new DietDay(dietDayData) : undefined;
      })
    );
  }
}
