import { IBitfLanguage } from '@interfaces';
import { bitfToTranslate } from '@bitf/utils/bitf-translate.utils';

const SUPPORTED_LANGUAGES: IBitfLanguage[] = [{ code: 'it', label: 'Italiano' }];

export const CONSTANTS = {
  META_TAGS: {
    TITLE: {
      DEFAULT_TITLE: bitfToTranslate('META_TAGS.DEFAULT_TITLE'),
      POSTFIX: bitfToTranslate('META_TAGS.TITLE_POSTFIX'),
    },
    DESCRIPTION: bitfToTranslate('META_TAGS.DESCRIPTION'),
  },
  SUPPORTED_LANGUAGES,
  DEFAULT_LANGUAGE: { code: 'it', label: 'Italiano' },
  LOCAL_STORAGE_VERSION: 1,
};
