import { NgModule, ErrorHandler } from '@angular/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';

// Modules included in main bundle (not lazy loaded)

import { APP_VERSION } from '@env/version';
import { BitfOAuthInterceptor } from '@bitf/interceptors/bitf-oauth-interceptor.service';
import { BitfRenewTokenInterceptor } from '@bitf/interceptors/bitf-renew-token-interceptor.service';
import { BitfRetryInterceptor } from '@bitf/interceptors/bitf-retry-interceptor.service';
import { BitfApiUiMessagesInterceptor } from '@bitf/interceptors/bitf-api-ui-messages-interceptor.service';
import { BitfLanguageInterceptor } from '@bitf/interceptors/bitf-language-interceptor.service';
import { BitfApiErrorsInterceptor } from '@bitf/interceptors/bitf-api-errors-interceptor.service';
// tslint:disable-next-line:max-line-length
import { BitfApiEnvelopeMapperInterceptor } from '@bitf/interceptors/bitf-api-envelope-mapper-interceptor.service';
import { BitfErrorHandlerService } from '@bitf/core/services/error-handler/bitf-error-handler.service';

const EXPORTS_COMPONENTS = [];

const COMPONENTS = [];
const ENTRY_COMPONENTS = [];

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', `.json?v=${APP_VERSION}`);
}

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BitfOAuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BitfRenewTokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BitfLanguageInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BitfApiUiMessagesInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BitfApiErrorsInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BitfApiEnvelopeMapperInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BitfRetryInterceptor,
      multi: true,
    },
    { provide: ErrorHandler, useClass: BitfErrorHandlerService },
  ],
  declarations: [...EXPORTS_COMPONENTS, ...COMPONENTS],
  exports: [...EXPORTS_COMPONENTS],
  entryComponents: [...ENTRY_COMPONENTS],
})
export class CommonCoreModule {}
