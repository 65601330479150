import { Component, OnInit, Input } from '@angular/core';
import { PhysicalActivity, SearchFoodResult } from '@web/core/models';

@Component({
  selector: 'aboca-item-info-row',
  templateUrl: './item-info-row.component.html',
  styleUrls: ['./item-info-row.component.scss'],
})
export class ItemInfoRowComponent implements OnInit {
  @Input()
  item: SearchFoodResult | PhysicalActivity | any;

  constructor() {}

  ngOnInit() {}
}
