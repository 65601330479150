import { Injectable, Injector } from '@angular/core';
import { formatDate } from '@angular/common';

import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { BitfApiService } from '@bitf/services/api/bitf-api.service';
import { User } from '@models';
import { environment } from '@env/environment';
import { BitfDynamicLocaleService } from '@bitf/services/locale/bitf-dynamic-locale.service';
import { bitfFormatDateISO } from '@bitf/utils/bitf-dates.utils';

@Injectable({
  providedIn: 'root',
})
export class UsersService extends BitfApiService {
  readonly profileIdKey = 'id_profilo';
  profileId: string;

  constructor(
    injector: Injector,
    private translateService: TranslateService,
    private bitfDynamicLocaleService: BitfDynamicLocaleService
  ) {
    super('users', injector);
  }

  getMe() {
    const headers = [];
    if (this.profileId) {
      headers.push({ headerName: this.profileIdKey, value: this.profileId });
    }
    return super.request<User>({
      path: '/me',
      method: 'GET',
      headers,
    });
  }

  getDietPDF({
    userId,
    startDate,
    endDate,
    authToken,
  }: {
    userId: string;
    startDate: Date;
    endDate: Date;
    authToken: string;
  }) {
    const start = bitfFormatDateISO(startDate);
    const end = bitfFormatDateISO(endDate);
    return this.fetch({
      method: 'GET',
      apiUrl: environment.apiUrl.replace('oapi', 'wapi'),
      id: userId,
      relation: `print-diet(startDate=${start},endDate=${end})`,
      responseType: 'arraybuffer',
      headers: [{ headerName: 'Authorization', value: `Bearer ${authToken}` }],
    });
  }

  getDefaultMyMealLabel(): Observable<string> {
    return this.translateService
      .get('FAVORITES.MY_MEALS.DEFAULT_TITLE')
      .pipe(
        map(
          (title: string) =>
            `${title} ${formatDate(new Date(), 'short', this.bitfDynamicLocaleService.locale.code)}`
        )
      );
  }
}
