<div class="d-flex flex-column h-100">
  <mat-toolbar class="flex-shrink-0 aboca-toolbar">
    <mat-toolbar-row>
      <aboca-header-close title="{{'FAVORITES.MY_MEALS.ADD_TO_DIARY' | translate}}"
        class="flex-grow-1"
        (close)="onClose()"></aboca-header-close>
    </mat-toolbar-row>
  </mat-toolbar>

  <div class="container-fluid py-2 flex-grow-1 aboca-scroll-y">
    <div class="mb-3 d-none d-md-block">{{'FAVORITES.EDIT_MEAL.COPY_TO_DIARY_LABEL' | translate}}</div>
    <form [formGroup]="form"
      class="row">
      <mat-form-field class="col-24 col-md-12">
        <input matInput
          [matDatepicker]="picker"
          formControlName="selectedDate"
          [placeholder]="'FAVORITES.MY_MEALS.CHOOSE_A_DATE' | translate">
        <mat-datepicker-toggle matSuffix
          [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker
          bitfResponsiveTouchUi></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="col-24 col-md-12">
        <mat-label>{{'FAVORITES.EDIT_MEAL.MEAL_TYPE' | translate}}</mat-label>
        <mat-select formControlName="diaryTimeId">
          <mat-option *ngFor="let translation of translations"
            [value]="translation.key">{{translation.value | translate}}</mat-option>
        </mat-select>
      </mat-form-field>
    </form>

    <div class="text-center mt-3">
      <button mat-flat-button
        class="text-uppercase"
        color="primary"
        (click)="onClose(true)">{{'COMMON.ADD' | translate}}</button>
    </div>
  </div>
</div>