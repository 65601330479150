import { SuperModel } from './super-model.model';
import { Meal } from './meal.model';
import { Consumption } from './consumption.model';
import { DiaryActivity } from './physical-activity';
import { Measure } from './measure.model';
import { ERecordState } from '@enums';

export class DietDay extends SuperModel {
  date: Date;
  id: string;
  userId: string;
  notes: string;
  type: string;
  state: string;
  weekNumber: number;
  plannedConsumption: Consumption;
  takenConsumption: Consumption;
  meals?: Meal[];
  activities?: DiaryActivity[];
  measures?: Measure[];

  constructor(object: any = {}) {
    super(object);
    this.activities = (this.activities || []).map(m => new DiaryActivity(m));
    this.measures = (this.measures || []).map(m => new Measure(m));

    const diaryTimeToMeasureMap = [];
    this.measures.forEach(measure => {
      if (diaryTimeToMeasureMap[measure.diaryTimeId - 1] === undefined) {
        diaryTimeToMeasureMap[measure.diaryTimeId - 1] = [];
      }
      diaryTimeToMeasureMap[measure.diaryTimeId - 1].push(measure);
    });
    this.meals = (this.meals || []).map(meal => {
      meal.measures = diaryTimeToMeasureMap[meal.diaryTimeId - 1];
      if (this.state === ERecordState.CONFIRMED) {
        meal.state = ERecordState.CONFIRMED;
      }
      return new Meal(meal);
    });
  }

  get serialised() {
    return {
      date: this.date,
      id: this.id,
      userId: this.userId,
      notes: this.notes,
      type: this.type,
      state: this.state,
      weekNumber: this.weekNumber,
      plannedConsumption: this.plannedConsumption.serialised,
      takenConsumption: this.takenConsumption.serialised,
      meals: this.meals && this.meals.map(e => e.serialised),
      activities: this.activities && this.activities.map(e => e.serialised),
      measures: this.measures && this.measures.map(e => e.serialised),
    };
  }
}
