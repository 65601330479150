import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

import { SearchFoodResult, PhysicalActivity } from '@models';

import { ISearchResult } from '@interfaces';
import { StoreService } from '@web/core/services';
import { bitfToTranslate } from '@bitf/utils/bitf-translate.utils';

@Component({
  selector: 'aboca-food-detail',
  templateUrl: './food-detail.component.html',
  styleUrls: ['./food-detail.component.scss'],
})
export class FoodDetailComponent implements OnInit {
  @Input()
  searchResult: SearchFoodResult | PhysicalActivity;

  @Output()
  back = new EventEmitter<void>();
  @Output()
  add = new EventEmitter<ISearchResult>();

  quantity: number;
  initialQuantity: number;
  kiloCalories: number;
  units: string;
  hasNutrients: boolean;
  favoriteId: string;

  constructor(private storeService: StoreService) {}

  ngOnInit() {
    if (this.searchResult instanceof SearchFoodResult) {
      this.kiloCalories = this.searchResult.nutrients.kiloCalories;
      this.initialQuantity = 100;
      this.units = bitfToTranslate('UNITS.GRAMS_OR_ML');
      this.hasNutrients = true;
      this.favoriteId = this.searchResult.nutrients.foodProductId;
    } else if (this.searchResult instanceof PhysicalActivity) {
      this.kiloCalories = this.searchResult.getKCalories(this.storeService.store.user.userData.weight, 60);
      this.initialQuantity = 60;
      this.units = bitfToTranslate('UNITS.MINUTES');
      this.hasNutrients = false;
    }

    this.quantity = this.initialQuantity;
  }

  onQuantityChange(quantity: number) {
    this.quantity = quantity;
  }

  onAdd() {
    if (this.quantity != null) {
      this.add.emit({
        toAdd: this.searchResult,
        quantity: this.quantity,
      });
    }
  }
}
