import { Injectable, Injector } from '@angular/core';
import { HttpResponse } from '@angular/common/http';

// tslint:disable-next-line:max-line-length
import { BitfQueryStringTokenAuthService } from '@bitf/services/auth/query-string-token/bitf-query-string-token-auth.service';

import { IQueryStringTokenMetadata, IBitfQueryStringLogin } from '@interfaces';
import { BITF_CONFIGS } from '@configs';
import { EBitfAuthState } from '@enums';
import { StorageService } from './storage.service';
import { BitfTryCatch } from '@bitf/core/decorators/bitf-try-catch.decorator';

const { getRouteFromUserState } = BITF_CONFIGS;

@Injectable({
  providedIn: 'root',
})
export class AuthService extends BitfQueryStringTokenAuthService<IQueryStringTokenMetadata> {
  readonly accessTokenKey = 'access_token';

  constructor(private _storageService: StorageService, injector: Injector) {
    super(injector);
  }

  @BitfTryCatch()
  handleAuthentication() {
    const accessToken = this.activatedRoute.snapshot.queryParamMap.get(this.accessTokenKey);
    const profileIdFromQuery = this.activatedRoute.snapshot.queryParamMap.get(this.usersService.profileIdKey);

    // each time accessToken is provided, storage.profileId must be set to the provided value or reset to null
    if (accessToken) {
      this._storageService.data = { profileId: profileIdFromQuery };
    }

    this.usersService.profileId = profileIdFromQuery || this._storageService.data.profileId;
    super.handleAuthentication(this.accessTokenKey);
  }

  authenticate(callback: (result: EBitfAuthState) => void) {
    const subscription = this.authState$.subscribe((authState: EBitfAuthState) => {
      if (authState === EBitfAuthState.LOGGED || authState === EBitfAuthState.LOGIN_ERROR) {
        subscription.unsubscribe();
        callback(authState);
      }
    });
    this.handleAuthentication();
  }

  handleRedirect(authState: EBitfAuthState) {
    if (authState === EBitfAuthState.LOGGED) {
      this.router.navigate([getRouteFromUserState(this.storeService.store.user.state)]);
    }
  }

  extractRenewToken(event: HttpResponse<any>) {
    return event.headers.get(BITF_CONFIGS.authService.renewToken.tokenHeaderKey);
  }

  signInWithToken(newToken: string) {
    this.signIn({
      encodedToken: newToken,
    });
  }

  decodeToken(loginResponse: IBitfQueryStringLogin): IQueryStringTokenMetadata {
    const tokenParts = loginResponse.encodedToken.split('.');
    const decodedToken = JSON.parse(atob(tokenParts[1]));
    const tokenMetadata: IQueryStringTokenMetadata = {
      token: loginResponse.encodedToken,
      expiresAt: decodedToken.exp * 1000,
      expiresIn: (decodedToken.exp - decodedToken.auth_time) * 1000,
      ...decodedToken,
    };

    return tokenMetadata;
  }

  isTokenValid() {
    return this.authTokenMetaData !== undefined;
  }

  isTokenExpired() {
    return this.authTokenMetaData === undefined;
  }

  clearAuthData() {
    // Auth token must not be cleared. There is an automatic token renew mechanism
  }
}
