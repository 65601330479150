import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { NotFoundComponent } from './core/components/not-found/not-found.component';

import { DesktopLayoutComponent } from './core/components/desktop-layout/desktop-layout.component';
import { MobileLayoutComponent } from './core/components/mobile-layout/mobile-layout.component';
import { SignInComponent } from './modules/login/sign-in/sign-in.component';
import { AuthGuard } from '@common/core/services/guards/auth.guard';
import { EUserState, EUserDefaultRoutes } from './enums';

const sharedRoutes: Routes = [
  {
    path: EUserDefaultRoutes.SIGNIN,
    component: SignInComponent,
  },
  {
    path: 'changelog',
    loadChildren: () =>
      import('./../../common/libs/bitforce/modules/changelog/bitf-changelog.module').then(
        m => m.BitfChangelogModule
      ),
  },
];

const routes: Routes = [
  { path: '', redirectTo: 'signin', pathMatch: 'full' },
  { path: 'm', redirectTo: 'm/signin', pathMatch: 'full' },
  {
    path: 'm',
    component: MobileLayoutComponent,
    children: [
      {
        path: EUserDefaultRoutes.WIZARD,
        loadChildren: './modules/wizard/mobile-wizard/mobile-wizard.module#MobileWizardModule',
        data: { showHeader: true, userState: EUserState.ProfileOnly },
        canActivate: [AuthGuard],
      },
      {
        path: EUserDefaultRoutes.PLANNING,
        loadChildren: './modules/planning-wizard/mobile-planning/mobile-planning.module#MobilePlanningModule',
        data: { showHeader: true, showNavbar: true, userState: EUserState.ProfileAndData },
        canActivate: [AuthGuard],
      },
      {
        path: EUserDefaultRoutes.DASHBOARD,
        loadChildren: './modules/dashboard/mobile-dashboard/mobile-dashboard.module#MobileDashboardModule',
        data: { showHeader: true, showNavbar: true, userState: EUserState.ProfileDataAndDiet },
        canActivate: [AuthGuard],
      },
      {
        path: EUserDefaultRoutes.WEIGHT_TARGET,
        loadChildren:
          './modules/weight-target/mobile-weight-target/mobile-weight-target.module#MobileWeightTargetModule',
        data: { showHeader: true, userState: EUserState.ProfileAndData },
        canActivate: [AuthGuard],
      },
      {
        path: EUserDefaultRoutes.INFO,
        loadChildren: './modules/info/info.module#InfoModule',
        data: { showHeader: true, showNavbar: true, userState: EUserState.ProfileOnly },
        canActivate: [AuthGuard],
      },
      {
        path: EUserDefaultRoutes.MY_PROGRESS,
        loadChildren: './modules/progress/progress.module#ProgressModule',
        data: { showHeader: true, showNavbar: true, userState: EUserState.ProfileDataAndDiet },
        canActivate: [AuthGuard],
      },
      {
        path: EUserDefaultRoutes.FAVORITES,
        loadChildren: './modules/favorites/favorites.module#FavoritesModule',
        data: { showHeader: true, showNavbar: true, userState: EUserState.ProfileOnly },
        canActivate: [AuthGuard],
      },
      ...sharedRoutes,
    ],
  },
  {
    path: '',
    component: DesktopLayoutComponent,
    children: [
      {
        path: EUserDefaultRoutes.WIZARD,
        loadChildren: './modules/wizard/desktop-wizard/desktop-wizard.module#DesktopWizardModule',
        data: { showHeader: true, userState: EUserState.ProfileOnly },
        canActivate: [AuthGuard],
      },
      {
        path: EUserDefaultRoutes.PLANNING,
        loadChildren:
          './modules/planning-wizard/desktop-planning/desktop-planning.module#DesktopPlanningModule',
        data: { showHeader: true, showNavbar: true, userState: EUserState.ProfileAndData },
        canActivate: [AuthGuard],
      },
      {
        path: EUserDefaultRoutes.DASHBOARD,
        loadChildren: './modules/dashboard/desktop-dashboard/desktop-dashboard.module#DesktopDashboardModule',
        data: { showHeader: true, showNavbar: true, userState: EUserState.ProfileDataAndDiet },
        canActivate: [AuthGuard],
      },
      {
        path: EUserDefaultRoutes.WEIGHT_TARGET,
        loadChildren:
          // tslint:disable-next-line:max-line-length
          './modules/weight-target/desktop-weight-target/desktop-weight-target.module#DesktopWeightTargetModule',
        data: { showHeader: true, userState: EUserState.ProfileAndData },
        canActivate: [AuthGuard],
      },
      {
        path: EUserDefaultRoutes.INFO,
        loadChildren: './modules/info/info.module#InfoModule',
        data: { showHeader: true, showNavbar: true, userState: EUserState.ProfileOnly },
        canActivate: [AuthGuard],
      },
      {
        path: EUserDefaultRoutes.MY_PROGRESS,
        loadChildren: './modules/progress/progress.module#ProgressModule',
        data: { showHeader: true, showNavbar: true, userState: EUserState.ProfileDataAndDiet },
        canActivate: [AuthGuard],
      },
      {
        path: EUserDefaultRoutes.FAVORITES,
        loadChildren: './modules/favorites/favorites.module#FavoritesModule',
        data: { showHeader: true, showNavbar: true, userState: EUserState.ProfileOnly },
        canActivate: [AuthGuard],
      },
      ...sharedRoutes,
    ],
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
