import { Component } from '@angular/core';

import { environment } from '@env/environment';

@Component({
  selector: 'aboca-desktop-layout',
  templateUrl: './desktop-layout.component.html',
  styleUrls: ['./desktop-layout.component.scss'],
})
export class DesktopLayoutComponent {
  wrapperVisible = false; // environment.name !== 'staging' && environment.name !== 'production';
}
