<div class="d-flex flex-column h-100">
  <mat-toolbar class="aboca-toolbar">
    <mat-toolbar-row>
      <aboca-header-close [title]="activity.name"
        class="flex-grow-1"
        (close)="onClose()"></aboca-header-close>
    </mat-toolbar-row>
  </mat-toolbar>

  <div class="flex-grow-1 flex-shrink-1 container-fluid aboca-scroll-y">
    <aboca-quantity-row [kiloCalories]="kiloCalories"
      units="{{'UNITS.MINUTES' | translate}}"
      [quantity]="activity.duration"
      [referenceQuantity]="activity.referenceQuantity"
      (quantityChange)="onDurationChange($event)"
      (submit)="onClose()">
    </aboca-quantity-row>
  </div>

  <div class="container-fluid flex-shrink-0 flex-grow-0 border-top">
    <div class="row">
      <div class="col d-flex flex-row flex-grow-1">
        <button mat-stroked-button
          class="m-1 col aboca-border-primary"
          (click)="onDelete()"
          color="">{{'COMMON.DELETE' | translate}}</button>

        <button mat-flat-button
          class="m-1 col"
          (click)="onClose()"
          color="primary">{{'COMMON.APPLY' | translate}}</button>
      </div>
    </div>
  </div>
</div>