<div [hidden]="selectedResult || selectedMeal"
  class="d-flex flex-column h-100">
  <form [formGroup]="form"
    (submit)="onSubmit($event)">
    <mat-toolbar class="position-relative"
      class="aboca-toolbar">
      <mat-toolbar-row>
        <aboca-header-close *ngIf="!usedAsComponent"
          class="flex-grow-1 search-title"
          [title]="title"
          (close)="onClose()"></aboca-header-close>
        <aboca-header-back *ngIf="usedAsComponent"
          class="flex-grow-1"
          [title]="title"
          (back)="onClose()"></aboca-header-back>
      </mat-toolbar-row>

      <mat-toolbar-row class="justify-content-center align-items-start">
        <mat-form-field appearance="fill"
          class="aboca-square-field">
          <input matInput
            #searchInput
            placeholder="{{'SEARCH.SEARCH_A_FOOD' | translate}}"
            (keydown)="resetValueIfBarcodeSearch()"
            (focus)="resetValueIfBarcodeSearch()"
            formControlName="search">
          <mat-icon matSuffix
            *ngIf="!isBarcodeSearchEnabled"
            class="aboca-grey-color">search</mat-icon>
          <mat-icon matSuffix
            class="barcode"
            *ngIf="isBarcodeSearchEnabled"
            (click)="onScanBarcode()"
            svgIcon="barcode"></mat-icon>
        </mat-form-field>
      </mat-toolbar-row>
    </mat-toolbar>
  </form>
  <mat-progress-bar [style.visibility]="progressVisible ? 'visible' : 'hidden'"
    mode="indeterminate"></mat-progress-bar>
  <div class="flex-grow-1 aboca-scroll-y d-flex flex-column position-relative">
    <mat-tab-group *ngIf="isFoodSearch"
      mat-align-tabs="center"
      class="h-100"
      (selectedTabChange)="onSelectedTabChange($event)">
      <mat-tab>
        <ng-template mat-tab-label>
          <div class="d-flex flex-column align-items-center">
            <mat-icon svgIcon="cherry"></mat-icon>
            <span class="text-uppercase mt-1 mat-small">{{'COMMON.FOODS' | translate}}</span>
          </div>
        </ng-template>
        <ng-container [ngTemplateOutlet]="resultList"
          [ngTemplateOutletContext]="{results: allResults}"></ng-container>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <div class="d-flex flex-column align-items-center">
            <mat-icon svgIcon="heart"></mat-icon>
            <span class="text-uppercase mt-1 mat-small">{{'COMMON.MY_FOODS' | translate}}</span>
          </div>
        </ng-template>
        <ng-container [ngTemplateOutlet]="resultList"
          [ngTemplateOutletContext]="{results: favorites}"></ng-container>
      </mat-tab>
      <mat-tab *ngIf="!myMealsHidden">
        <ng-template mat-tab-label>
          <div class="d-flex flex-column align-items-center">
            <mat-icon svgIcon="heart"></mat-icon>
            <span class="text-uppercase mt-1 mat-small">{{'COMMON.MY_MEALS' | translate}}</span>
          </div>
        </ng-template>
        <ng-container *ngTemplateOutlet="myMealsList"></ng-container>
      </mat-tab>
    </mat-tab-group>

    <ng-container *ngIf="!isFoodSearch">
      <ng-container [ngTemplateOutlet]="resultList"
        [ngTemplateOutletContext]="{results: allResults}"></ng-container>
    </ng-container>

    <div class="centered">
      <div *ngIf="!progressVisible && searchWord.length >= minDigits && !hasResults() ">
        {{'SEARCH.NO_RESULTS' | translate}}
      </div>
      <div *ngIf="searchWord.length < minDigits && !fetchAll && tabIndex === 0">
        {{'SEARCH.INPUT_3_CHARS_AT_LEAST' | translate}}</div>
    </div>
  </div>
</div>

<aboca-food-detail *ngIf="selectedResult"
  [searchResult]="selectedResult"
  (back)="selectedResult = undefined"
  (add)="onAdd($event)"></aboca-food-detail>

<aboca-my-meal-edit *ngIf="selectedMeal"
  [readonly]="true"
  [meal]="selectedMeal"
  [usedAsComponent]="true"
  [hasContent]="true"
  (closed)="selectedMeal = undefined">
  <button mat-flat-button
    (click)="onAddMeal(selectedMeal)"
    color="primary"
    class="flex-grow-1">{{'COMMON.ADD' | translate}}</button>
</aboca-my-meal-edit>

<ng-template #resultList
  let-results="results">
  <mat-action-list>
    <button *ngFor="let result of results"
      mat-list-item
      (click)="onSelectedResult(result)">
      <div class="d-flex w-100 align-items-center border-bottom py-1">
        <aboca-item-info-row [item]="result"></aboca-item-info-row>
        <mat-icon class="aboca-grey-color">arrow_forward_ios</mat-icon>
      </div>
    </button>
  </mat-action-list>
</ng-template>

<ng-template #myMealsList>
  <mat-action-list>
    <button *ngFor="let myMeal of myMeals"
      mat-list-item
      (click)="onSelectedMeal(myMeal)">
      <div class="d-flex w-100 align-items-center border-bottom py-1">
        <div class="d-flex flex-column flex-grow-1">
          <span class="mat-h3 mb-0 text-truncate">{{myMeal.label}}</span>
          <span>{{myMeal.nutrients.kiloCalories | number:'1.0-0'}} {{'UNITS.KCAL' | translate}}</span>
        </div>
        <mat-icon class="aboca-grey-color">arrow_forward_ios</mat-icon>
      </div>
    </button>
  </mat-action-list>
</ng-template>