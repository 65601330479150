import { Component, NgZone } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { AppSessionService, InAppService } from '@services';
import { BitfLogDnaSenderService } from '@bitf/core/services/logger/bitf-log-dna-sender.service';

@Component({
  selector: 'aboca-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    appSessionService: AppSessionService,
    bitfLogDnaSenderService: BitfLogDnaSenderService,
    inAppService: InAppService,
    ngZone: NgZone
  ) {
    appSessionService.init();

    bitfLogDnaSenderService.init();

    window['onNativeEvent'] = (event: string, payload: any) => {
      ngZone.run(() => {
        inAppService.processNativeEvent(event, payload);
      });
    };

    const basePath = '/assets/custom-icons/';
    this.matIconRegistry
      .addSvgIcon('fats', this.domSanitizer.bypassSecurityTrustResourceUrl(`${basePath}fats.svg`))
      .addSvgIcon(
        'carbohydrates',
        this.domSanitizer.bypassSecurityTrustResourceUrl(`${basePath}carbohydrates.svg`)
      )
      .addSvgIcon('proteins', this.domSanitizer.bypassSecurityTrustResourceUrl(`${basePath}proteins.svg`))
      .addSvgIcon('fibers', this.domSanitizer.bypassSecurityTrustResourceUrl(`${basePath}fibers.svg`))
      .addSvgIcon(
        'fruits',
        this.domSanitizer.bypassSecurityTrustResourceUrl(`${basePath}fruits-and-vegetables.svg`)
      )
      .addSvgIcon('alcool', this.domSanitizer.bypassSecurityTrustResourceUrl(`${basePath}alcohol.svg`))
      .addSvgIcon('sodium', this.domSanitizer.bypassSecurityTrustResourceUrl(`${basePath}sodium.svg`))
      .addSvgIcon('glycemia', this.domSanitizer.bypassSecurityTrustResourceUrl(`${basePath}glycemia.svg`))
      .addSvgIcon(
        'physical-activity',
        this.domSanitizer.bypassSecurityTrustResourceUrl(`${basePath}shoes-icon.svg`)
      )
      .addSvgIcon('diet', this.domSanitizer.bypassSecurityTrustResourceUrl(`${basePath}apple-icon.svg`))
      .addSvgIcon(
        'empty-glass',
        this.domSanitizer.bypassSecurityTrustResourceUrl(`${basePath}empty-glass-icon.svg`)
      )
      .addSvgIcon(
        'water-glass',
        this.domSanitizer.bypassSecurityTrustResourceUrl(`${basePath}water-glass-icon.svg`)
      )
      .addSvgIcon('red-warn', this.domSanitizer.bypassSecurityTrustResourceUrl(`${basePath}red-warn.svg`))
      .addSvgIcon(
        'yellow-warn',
        this.domSanitizer.bypassSecurityTrustResourceUrl(`${basePath}yellow-warn.svg`)
      )
      .addSvgIcon(
        'check',
        this.domSanitizer.bypassSecurityTrustResourceUrl(`${basePath}baseline-check-24px.svg`)
      )
      .addSvgIcon(
        'clear',
        this.domSanitizer.bypassSecurityTrustResourceUrl(`${basePath}baseline-clear-24px.svg`)
      )
      .addSvgIcon('heart', this.domSanitizer.bypassSecurityTrustResourceUrl(`${basePath}cuore.svg`))
      .addSvgIcon('cherry', this.domSanitizer.bypassSecurityTrustResourceUrl(`${basePath}fragola.svg`))
      .addSvgIcon('cutlery', this.domSanitizer.bypassSecurityTrustResourceUrl(`${basePath}posate.svg`))
      .addSvgIcon('barcode', this.domSanitizer.bypassSecurityTrustResourceUrl(`${basePath}barcode.svg`));
  }
}
