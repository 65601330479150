import { SuperModel } from './super-model.model';

export class Config extends SuperModel {
  tolerances: Tolerance[];
  constructor(data: any) {
    super(data);

    this.tolerances = (this.tolerances || []).map(t => new Tolerance(t));
  }

  get serialised() {
    return {
      tolerances: this.tolerances && this.tolerances.map(e => e.serialised),
    };
  }
}

export class Tolerance extends SuperModel {
  nutrient: string;
  tolerance: number;
  warnType: WarnType;
  unit: string;

  constructor(data: any) {
    super(data);
    if (this.unit === null) {
      this.unit = '';
    }
  }

  get serialised() {
    return {
      nutrient: this.nutrient,
      tolerance: this.tolerance,
      warnType: this.warnType,
      unit: this.unit,
    };
  }
}

export enum WarnType {
  EXCESS = 'Excess',
  NONE = 'None',
  LOW = 'Low',
}
