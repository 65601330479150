import { SuperModel } from './super-model.model';
import { Food, NutritionData, FoodGroup } from './food.model';
import { ERecordState } from './meal-record-state';
import { Measure, EMeasures } from './measure.model';
import { bitfToTranslate } from '@bitf/utils/bitf-translate.utils';

const measureSorter = (m1: Measure, m2: Measure) => {
  return m2.timestamp.getTime() - m1.timestamp.getTime();
};

export class Meal extends SuperModel {
  title: string;
  notes: string;
  diaryTimeId: EDiaryTimeId;
  records: Food[];
  nonGroupedRecords: Food[];
  state: ERecordState;
  measures?: Measure[];
  foodGroups?: FoodGroup[] = [];
  foodGroupById?: Map<string, FoodGroup> = new Map();

  private measuresTypeMap: MeasuresTypeMap;

  constructor(object: any = {}) {
    super(object);
    this.records = (this.records || []).map(r => new Food(r));
    this.createFoodGroups();
    this.nonGroupedRecords = this.records.filter(record => record.groupId === undefined);
    this.measuresTypeMap = new MeasuresTypeMap();
    this.measures = (this.measures || []).map(m => {
      const measure = new Measure(m);
      this.measuresTypeMap.addMeasure(measure);
      return measure;
    });
    this.measuresTypeMap.getAllMeasures().forEach(measures => {
      measures.sort(measureSorter);
    });
  }

  get isConfirmed(): boolean {
    return this.state === ERecordState.CONFIRMED;
  }

  getTotalKCalories(): number {
    return this.records.reduce((accumulator, currentValue) => accumulator + currentValue.kiloCalories, 0);
  }

  getNutritionData(): NutritionData {
    const aggregation = this.records.reduce(
      (accumulator, record) => {
        const newValue = {};
        for (const key in record) {
          if (record.hasOwnProperty(key)) {
            newValue[key] = (accumulator[key] || 0) + (record[key] || 0);
          }
        }
        return newValue;
      },
      {} as any
    );

    delete aggregation.product; // product is not aggragated and will break NutritonData

    return new NutritionData(aggregation);
  }

  getLatestMeasuresOfType(measureTypeId: number): Measure {
    return this.measuresTypeMap.getMeasuresOfType(measureTypeId)[0];
  }

  get glycemia() {
    return this.getLatestMeasuresOfType(EMeasures.GLYCEMIA);
  }

  addMeasure(measure: Measure) {
    this.measures.push(measure);
    this.measuresTypeMap.addMeasure(measure);
    this.measuresTypeMap.getMeasuresOfType(measure.measureTypeId).sort(measureSorter);
  }

  get serialised() {
    return {
      title: this.title,
      diaryTimeId: this.diaryTimeId,
      records: this.records && this.records.map(e => e.serialised),
    };
  }

  private createFoodGroups() {
    this.records
      .filter(record => record.groupId !== undefined)
      .forEach(record => {
        const hasGroup = this.foodGroupById.has(record.groupId);
        const group = hasGroup
          ? this.foodGroupById.get(record.groupId)
          : new FoodGroup({ groupId: record.groupId, groupName: record.groupName });

        group.foods.push(record);
        if (!hasGroup) {
          this.foodGroupById.set(record.groupId, group);
          this.foodGroups.push(group);
        }
      });
  }
}

class MeasuresTypeMap {
  private measuresTypeMap: Measure[][] = [];

  addMeasure(measure: Measure) {
    const measures = this.getMeasuresOfType(measure.measureTypeId);
    measures.push(measure);
  }

  getAllMeasures(): Measure[][] {
    return this.measuresTypeMap;
  }

  getMeasuresOfType(measureTypeId: number): Measure[] {
    if (this.measuresTypeMap[measureTypeId] === undefined) {
      this.measuresTypeMap[measureTypeId] = [];
    }
    return this.measuresTypeMap[measureTypeId];
  }
}

export enum EDiaryTimeId {
  BREAKFAST = 1,
  SNACK = 2,
  LUNCH = 3,
  AFTERNOON_SNACK = 4,
  DINNER = 5,
  OTHER_MEALS = 6,
}

export const DiaryTimeTranslationMap = {
  [EDiaryTimeId.BREAKFAST]: bitfToTranslate('MEAL.BREAKFAST'),
  [EDiaryTimeId.SNACK]: bitfToTranslate('MEAL.SNACK'),
  [EDiaryTimeId.LUNCH]: bitfToTranslate('MEAL.LUNCH'),
  [EDiaryTimeId.AFTERNOON_SNACK]: bitfToTranslate('MEAL.AFTERNOON_SNACK'),
  [EDiaryTimeId.DINNER]: bitfToTranslate('MEAL.DINNER'),
  [EDiaryTimeId.OTHER_MEALS]: bitfToTranslate('MEAL.OTHER_MEALS'),
};
