import { CONSTANTS as COMMON_CONSTANTS } from '@common/constants/common.constants';
// tslint:disable-next-line:max-line-length
import { BitfMatOkCancelDialogComponent } from '@bitf/core/components/ui/ok-cancel-dialog/material';

export const WEB_CONSTANTS = {
  fullScreenMessageComponent: null,
  okCancelDialogComponent: BitfMatOkCancelDialogComponent,
  roundProgressColor: '#FF5722',
  roundProgressActivityColor: '#50d1c6',
  DIALOG_100_OPTIONS: {
    maxWidth: '100vw',
    width: '100%',
    height: '100%',
    panelClass: 'dialog-100',
    autoFocus: false,
  },
};

export const CONSTANTS = Object.assign(COMMON_CONSTANTS, WEB_CONSTANTS);
