import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'aboca-header-back',
  templateUrl: './header-back.component.html',
  styleUrls: ['./header-back.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderBackComponent implements OnInit {
  @Input()
  title: string;

  @Output()
  back = new EventEmitter<void>();

  constructor() {}

  ngOnInit() {}
}
