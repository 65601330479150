import * as Models from '@models';

export const modelsMap = new Map<string, any>([
  ['users', Models.User],
  ['food-product', Models.SearchFoodResult],
  ['diary', Models.DietDay],
  ['food', Models.Food],
  ['diet', Models.Diet],
  ['physical-activity', Models.PhysicalActivity],
  ['diary-activity', Models.DiaryActivity],
  ['diet-type', Models.DietType],
  ['favoriteFoods', Models.Favorite],
  ['myMeals', Models.MyMeal],
  ['weekly-state-range', Models.WeeklyStateRange],
  ['config', Models.Config],
  ['suggested-meals', Models.SuggestedMeal],
]);
