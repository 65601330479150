import { Injectable, Injector } from '@angular/core';

// tslint:disable-next-line:max-line-length
import { BitfWebUiMessagesListenerService } from '@bitf/services/ui-messages-listener/web/bitf-web-ui-messages-listener.service';
import { IBitfUiMessages } from '@interfaces';
import { EBitfUiMessageType } from '@enums';

@Injectable({
  providedIn: 'root',
})
export class UiMessagesListenerService extends BitfWebUiMessagesListenerService {
  constructor(protected injector: Injector) {
    super(injector);
  }
}
