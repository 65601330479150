import { EventEmitter, Component, Input, Output, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'aboca-wizard-footer',
  templateUrl: './wizard-footer.component.html',
  styleUrls: ['./wizard-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WizardFooterComponent {
  @Input()
  disabled: boolean;
  @Input()
  label: string;
  @Output()
  next = new EventEmitter<void>();
}
