import { BitfStorage } from '@bitf/core/models/bitf-storage.model';

export class AbocaStorage extends BitfStorage {
  userData?: any;
  profileId?: string;

  constructor(storageData: Partial<AbocaStorage>) {
    super(storageData);
  }
}
