<aboca-header *ngIf="showHeader"
  class="fixed-top fixed"></aboca-header>
<mat-sidenav-container>
  <mat-sidenav #sidenav>
    <ng-container #sidenavContent></ng-container>
  </mat-sidenav>
  <mat-sidenav-content class="aboca-fixed-viewport aboca-scroll-y aboca-bottom-navbar-padding"
    [class.aboca-content-without-header]="!showHeader"
    [class.aboca-content-without-footer]="!showNavbar">

    <router-outlet></router-outlet>

  </mat-sidenav-content>
</mat-sidenav-container>

<aboca-mobile-navbar class="fixed-bottom fixed"
  *ngIf="showNavbar"></aboca-mobile-navbar>
<bitf-loader></bitf-loader>