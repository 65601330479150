import { SuperModel } from './super-model.model';

export class Manufacturer extends SuperModel {
  name: string;
  lastUpdate: Date;

  get serialised() {
    return {
      id: this.id,
      name: this.name,
      lastUpdate: this.lastUpdate,
    };
  }
}
