import { Component, OnInit } from '@angular/core';

import { AuthService, LoaderService } from '@services';
import { EBitfAuthState } from '@enums';

@Component({
  selector: 'aboca-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent implements OnInit {
  showError = false;

  constructor(private authService: AuthService, private loaderService: LoaderService) {}

  ngOnInit() {
    const subscription = this.authService.authState$.subscribe((authState: EBitfAuthState) => {
      if (authState === EBitfAuthState.LOGGED || authState === EBitfAuthState.LOGIN_ERROR) {
        subscription.unsubscribe();
        this.loaderService.hide();
        this.showError = authState === EBitfAuthState.LOGIN_ERROR;
      }

      this.authService.handleRedirect(authState);
    });
    this.authService.handleAuthentication();
  }
}
