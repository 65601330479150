import { Injectable, Injector } from '@angular/core';

import { BitfApiService } from '@bitf/services/api/bitf-api.service';

@Injectable({ providedIn: 'root' })
export class PhysicalActivityService extends BitfApiService {
  constructor(injector: Injector) {
    super('physical-activity', injector);
  }
}
