<div class="d-flex flex-column h-100"
  *ngIf="meal && state === 'NORMAL'">
  <mat-toolbar class="flex-shrink-0 aboca-toolbar"
    *ngIf="state === 'NORMAL'">
    <mat-toolbar-row>
      <aboca-favorites-header *ngIf="!usedAsComponent"
        [saveIcon]="!readonly"
        [title]="'FAVORITES.EDIT_MEAL.TITLE' | translate"
        class="flex-grow-1"
        (close)="onClose($event)">
      </aboca-favorites-header>
      <aboca-header-back *ngIf="usedAsComponent"
        title="{{'FAVORITES.EDIT_MEAL.TITLE' | translate}}"
        class="flex-grow-1"
        (back)="onClose()"></aboca-header-back>
    </mat-toolbar-row>
  </mat-toolbar>

  <div class="container-fluid py-2 flex-grow-1 aboca-scroll-y"
    *ngIf="meal && state === 'NORMAL'">
    <h2 class="mb-0">{{'FAVORITES.EDIT_MEAL.MEAL_DATA' | translate}}</h2>
    <mat-divider></mat-divider>
    <form [formGroup]="form"
      *ngIf="form">
      <mat-form-field class="pt-3 w-100">
        <mat-label>{{'FAVORITES.EDIT_MEAL.MEAL_LABEL' | translate}}</mat-label>
        <input matInput
          [readonly]="readonly"
          formControlName="mealLabel">
      </mat-form-field>
    </form>

    <h2 class="mb-0">{{'FAVORITES.EDIT_MEAL.MEAL_CONTENT' | translate}}</h2>
    <mat-divider></mat-divider>
    <aboca-centered-add-button label="{{'COMMON.ADD_A_FOOD'}}"
      *ngIf="!readonly"
      (add)="onAdd()"></aboca-centered-add-button>
    <mat-action-list *ngIf="!readonly">
      <button *ngFor="let record of records"
        mat-list-item
        (click)="onSelectedRecord(record)">
        <div class="d-flex w-100 align-items-center border-bottom py-1">
          <aboca-item-info-row [item]="record.product"></aboca-item-info-row>
          <mat-icon class="aboca-grey-color">arrow_forward_ios</mat-icon>
        </div>
      </button>
    </mat-action-list>
    <mat-list *ngIf="readonly">
      <mat-list-item *ngFor="let record of records">
        <aboca-item-info-row [item]="record.product"></aboca-item-info-row>
      </mat-list-item>
    </mat-list>

    <mat-divider class="my-3"></mat-divider>

    <aboca-food-nutrients-table [nutritionData]="meal.getComputedNutritionData()"
      [subHeader]=""></aboca-food-nutrients-table>
  </div>

  <!-- Hides the close button if there is a transcluded content -->
  <div *ngIf="!hasContent"
    class="flex-shrink-0 py-1 d-flex border-top flex-column flex-sm-row"
    [ngClass]="{'d-none': ref?.innerHTML.trim()}">
    <div class="col col-sm-12"
      *ngIf="!readonly">
      <button mat-stroked-button
        [disabled]="!deleteEnabled"
        color=""
        class="w-100 aboca-border-primary"
        (click)="onDeleteMeal()">{{'COMMON.DELETE' | translate}}</button>
    </div>
    <div class="col col-sm-12"
      *ngIf="addToMyRecipesVisible">
      <button mat-stroked-button
        [disabled]="records.length === 0"
        color=""
        class="w-100 aboca-border-primary"
        (click)="onAddToMyRecipes()">{{'FAVORITES.MY_MEALS.ADD_TO_MY_RECIPES' | translate}}</button>
    </div>
    <div class="col col-sm-12 mt-1 mt-sm-0">
      <button mat-flat-button
        [disabled]="records.length === 0"
        color="primary"
        class="w-100"
        (click)="onAddToDiary()">{{'FAVORITES.MY_MEALS.ADD_TO_DIARY' | translate}}</button>
    </div>
  </div>

  <!-- Hides the content wrapper div if there is no content -->
  <div *ngIf="hasContent"
    class="flex-shrink-0 p-3 d-flex border-top">
    <ng-content></ng-content>
  </div>
</div>

<aboca-search *ngIf="state === 'ADDING'"
  [usedAsComponent]="true"
  title="{{meal.getCombinationLabel() | translate}}"
  [searchService]="foodProductService"
  [myMealsHidden]="true"
  (added)="onItemAdded($event)"
  (closed)="onSearchClosed()"></aboca-search>

<aboca-food-edit *ngIf="state === 'EDIT'"
  [food]="this.selectedRecord.toFood()"
  [closeButton]="false"
  (back)="onEditBack()"
  (apply)="onEditApply($event)"
  (delete)="onSelectedDelete()"></aboca-food-edit>