import { Component, OnInit } from '@angular/core';

import { EBitfSidenavPosition, EBitfSidenavMode } from '@enums';
import { environment } from '@env/environment';

import { MenuComponent } from '../menu/menu.component';
import { BitfMatSidenavService } from '@bitf/core/services/sidenav/material/bitf-mat-sidenav.service';

@Component({
  selector: 'aboca-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  menuComponent = MenuComponent;
  hasMenu: boolean;

  constructor(private mSidenavService: BitfMatSidenavService) {}

  ngOnInit() {
    this.hasMenu = !environment.production;
  }

  openMenu() {
    this.mSidenavService.open({
      component: this.menuComponent,
      sidenavOptions: { mode: EBitfSidenavMode.OVER, position: EBitfSidenavPosition.END },
    });
  }
}
