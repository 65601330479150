<div class="d-flex justify-content-between">
  <div class="d-inline-flex flex-column align-items-baseline "
    [class.flex-with-truncate-child]="textTruncate">
    <span [class.text-truncate]="textTruncate"
      [class.mw-100]="textTruncate">{{item.name}} <mat-icon *ngIf="item.state === ERecordState.CONFIRMED"
        svgIcon="check"
        class="check"></mat-icon>
      <mat-icon *ngIf="item.state === ERecordState.NOT_DONE"
        svgIcon="clear"
        class="clear"></mat-icon>
    </span>
    <span class="aboca-grey-color mat-small">
      <span *ngIf="item.quantity != null">
        <span> {{item.quantity | number:'1.1-1'}} {{item.unit | translate}}</span>
        <span> - {{kiloCalories | bitfNumber:'1.1-1'}} Kcal</span>
      </span>
      <span *ngIf="item.quantity == null">
        <span>{{kiloCalories | bitfNumber:'1.1-1'}} Kcal</span>
      </span>
      <span class="d-none d-md-inline"
        *ngIf="hasNutrients">
        <span class="pl-2">|</span>
        <span class="pl-2">Grassi:
          {{item.fats | bitfNumber:'1.1-1'}} <span
            *ngIf="!nullOrNaN(item.fats)">{{'UNITS.GRAMS_SYMBOL' | translate}}</span></span>
        <span class="pl-2">- Proteine:
          {{item.proteins | bitfNumber:'1.1-1'}} <span
            *ngIf="!nullOrNaN(item.proteins)">{{'UNITS.GRAMS_SYMBOL' | translate}}</span></span>
        <span class="pl-2">- Carboidrati:
          {{item.carbohydrates | bitfNumber:'1.1-1'}} <span
            *ngIf="!nullOrNaN(item.carbohydrates)">{{'UNITS.GRAMS_SYMBOL' | translate}}</span></span>
        <span class="pl-2">- Fibre:
          {{item.fibers | bitfNumber:'1.1-1'}} <span
            *ngIf="!nullOrNaN(item.fibers)">{{'UNITS.GRAMS_SYMBOL' | translate}}</span></span>
      </span>
    </span>
  </div>
  <button type="button"
    *ngIf="editable"
    class="aboca-grey-color"
    (click)="edit.next()"
    (mousedown)="$event.stopPropagation()"
    (touchstart)="$event.stopPropagation()"
    mat-icon-button>
    <mat-icon>edit</mat-icon>
  </button>
</div>