<div class="row no-gutters align-items-center">
  <div class="col-6"></div>
  <div class="col text-center text-truncate title">{{title | translate}}</div>
  <div class="col-6 text-right aboca-black-color">
    <button type="button"
      class="red"
      *ngIf="saveIcon"
      (click)="close.emit({save:false})"
      mat-icon-button>
      <mat-icon>close</mat-icon>
    </button>
    <button type="button"
      *ngIf="saveIcon"
      (click)="close.emit({save:true})"
      mat-icon-button>
      <mat-icon>check_circle_outline</mat-icon>
    </button>
    <button type="button"
      *ngIf="!saveIcon"
      (click)="close.emit()"
      mat-icon-button>
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>