import { Injectable, Injector } from '@angular/core';
import { BitfApiService } from '@bitf/services/api/bitf-api.service';
import { IBitfApiResponse } from '@interfaces';
import { SuggestedMeal } from '@models';
import { Observable } from 'rxjs';
import { StoreService } from '../store.service';
import { EBitfApiSortDirection } from '@web/enums';

@Injectable({ providedIn: 'root' })
export class SuggestedMealsService extends BitfApiService {
  constructor(private storeService: StoreService, injector: Injector) {
    super('suggested-meals', injector);
  }

  getSuggestedMeals(searchWord?: string): Observable<IBitfApiResponse<SuggestedMeal[]>> {
    return this.get<SuggestedMeal>({
      embed: ['need,eatingHabit,dietType'],
      filter: searchWord
        ? // tslint:disable-next-line:quotemark
          `contains(tolower(name),'${searchWord.toLowerCase().replace(/'/g, "''")}')`
        : undefined,
      sorting: [{ property: 'name', direction: EBitfApiSortDirection.ASC }],
    });
  }

  copyToUserMeals(suggestedMealId: string) {
    return this.post<any>({
      id: suggestedMealId,
      relation: 'copy-to-user-meals',
      isBodyRaw: true,
      body: {
        userId: this.storeService.store.user.id,
      },
    });
  }
}
