<div class="row no-gutters align-items-center">
  <div class="col-2"></div>
  <div class="col text-center text-truncate title"
    *ngIf="title !== undefined">{{title | translate}}</div>
  <div *ngIf="title === undefined"
    class="col text-center text-truncate">
    <ng-content></ng-content>
  </div>
  <div class="col-3 text-right aboca-black-color">
    <button type="button"
      (click)="close.emit()"
      mat-icon-button>
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>