import { SuperModel } from './super-model.model';
import { bitfFormatDateISO } from '@bitf/utils/bitf-dates.utils';

export class DietMenuProposal extends SuperModel {
  dietType: number;
  startDate: Date;
  daysNumber: number;

  get serialised() {
    return {
      dietType: this.dietType,
      startDate: bitfFormatDateISO(this.startDate),
      daysNumber: this.daysNumber,
    };
  }
}
