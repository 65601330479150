import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'aboca-wizard-header',
  templateUrl: './wizard-header.component.html',
  styleUrls: ['./wizard-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WizardHeaderComponent {
  @Input()
  link: string;
  @Input()
  label: string;
}
