import { SuperModel } from './super-model.model';
import { bitfFormatDateISO } from '@bitf/utils/bitf-dates.utils';

export class Diet extends SuperModel {
  completionDate: Date;
  startDate: Date;
  userId: string;
  weekdayCaloriesConsumption = 0;
  festivityCaloriesConsumption = 0;
  weightVariation = null;
  targetWeight: number;
  targetActivityCalories: number;
  targetCalories: number;
  weeksDuration: number;

  constructor(data = {}) {
    super(data);
    if (this.completionDate != null) {
      this.completionDate = new Date(this.completionDate);
    }
    if (this.startDate != null) {
      this.startDate = new Date(this.startDate);
    }
  }

  get serialised() {
    return {
      completionDate: bitfFormatDateISO(this.completionDate),
      startDate: bitfFormatDateISO(this.startDate),
      userId: this.userId,
      weekdayCaloriesConsumption: this.weekdayCaloriesConsumption,
      festivityCaloriesConsumption: this.festivityCaloriesConsumption,
      weightVariation: this.weightVariation,
      targetWeight: this.targetWeight,
      targetActivityCalories: this.targetActivityCalories,
      targetCalories: this.targetCalories,
      weeksDuration: this.weeksDuration,
    };
  }
}

export enum ETargetCaloriesMode {
  SIMPLIFIED_PROCEDURE = 'Automatic',
  FULL_PROCEDURE = 'Manual',
}
