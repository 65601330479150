import {
  Component,
  OnInit,
  Inject,
  Optional,
  Output,
  EventEmitter,
  Input,
  OnDestroy,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';

import { timer, Subject, of, ConnectableObservable, Observable, merge } from 'rxjs';
import {
  filter,
  debounce,
  distinctUntilChanged,
  switchMap,
  tap,
  map,
  takeUntil,
  catchError,
  multicast,
} from 'rxjs/operators';

import { FoodProductService, UsersService, StoreService, InAppService } from '@services';
import { SearchFoodResult, PhysicalActivity, Favorite, MyMeal } from '@models';
import { IBitfApiResponse, IBitfCloseEvent, ISearchResult, IBitfApiRequest } from '@interfaces';
import { EBitfCloseEventStatus, EStoreActions } from '@enums';
import { BitfApiService } from '@bitf/core/services/api/bitf-api.service';
import { BitfMatSidenavService } from '@bitf/core/services/sidenav/material/bitf-mat-sidenav.service';

@Component({
  selector: 'aboca-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit, OnDestroy {
  @ViewChild('searchInput', { static: true })
  searchInput: ElementRef<HTMLInputElement>;

  @Output()
  closed = new EventEmitter<void>();
  @Output()
  added = new EventEmitter<ISearchResult>();
  @Input()
  title: string;
  @Input()
  searchService: BitfApiService;
  @Input()
  usedAsComponent = false;
  @Input()
  fetchAll = false;
  @Input()
  myMealsHidden = false;

  form: FormGroup;
  favorites: SearchFoodResult[] = [];
  allResults: SearchFoodResult[] | PhysicalActivity[] = [];
  selectedResult: SearchFoodResult | PhysicalActivity;
  selectedMeal: MyMeal;
  myMeals: MyMeal[] = [];
  weight: number;
  progressVisible = false;
  searchWord = '';
  readonly minDigits = 3;
  isFoodSearch: boolean;
  isBarcodeSearchEnabled: boolean;
  isBarcodeSearch = false;
  tabIndex = 0;

  private unsubscribe$ = new Subject<void>();
  private fetchAbort$ = new Subject<void>();
  private isFetchingResult = false;
  private isFetchingMeals = false;
  private isFetchingFavorites = false;

  constructor(
    private formBuilder: FormBuilder,
    private bitfMatSidenavService: BitfMatSidenavService,
    private usersService: UsersService,
    private storeService: StoreService,
    private inAppService: InAppService,
    @Optional() private dialogRef: MatDialogRef<SearchComponent>,
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    data: { title?: string; searchService?: BitfApiService; fetchAll?: boolean }
  ) {
    const { title, searchService, fetchAll } =
      data || <{ title?: string; searchService?: BitfApiService; fetchAll?: boolean }>{};
    this.title = title;
    this.searchService = searchService;
    this.fetchAll = fetchAll;
    this.isBarcodeSearchEnabled = inAppService.isApotecaNaturaWebView();
  }

  ngOnInit() {
    this.isFoodSearch = this.searchService instanceof FoodProductService;
    this.weight = this.storeService.store.user.userData.weight;

    this.form = this.formBuilder.group({
      search: [''],
    });

    if (this.fetchAll) {
      this.fetchResults('').subscribe(
        (res: IBitfApiResponse<SearchFoodResult[]>) => (this.allResults = res.content)
      );
    }

    const formChanged = this.form.get('search').valueChanges.pipe(
      takeUntil(this.unsubscribe$),
      tap(() => this.fetchAbort$.next()),
      debounce(() => timer(500)),
      distinctUntilChanged(),
      map((value: string) => value.trim()),
      tap((value: string) => (this.searchWord = value))
    );

    const searchChanged = merge(formChanged, of('')).pipe(
      multicast(() => new Subject())
    ) as ConnectableObservable<string>;

    this.updateProgressOnValueChanges(searchChanged);
    this.fetchResultsOnValueChanges(searchChanged);
    this.fetchFavoritesOnValueChanges(searchChanged);
    this.fetchMyMealsOnValueChanges(searchChanged);
    searchChanged.connect();

    this.listenToBarcodeEvents();
  }

  hasResults(): boolean {
    if (this.tabIndex === 2) {
      return this.myMeals.length > 0;
    } else if (this.tabIndex === 1) {
      return this.favorites.length > 0;
    } else {
      return this.allResults.length > 0;
    }
  }

  onSelectedResult(result: SearchFoodResult) {
    this.selectedResult = result;
  }

  onSelectedMeal(myMeal: MyMeal) {
    this.selectedMeal = myMeal;
  }

  onAdd(payload: ISearchResult) {
    this.added.emit(payload);
    this.closeDialogIfNeeded(payload);
  }

  onAddMeal(myMeal: MyMeal) {
    const payLoad: ISearchResult = {
      toAdd: myMeal,
      quantity: 1,
    };
    this.added.emit(payLoad);
    this.closeDialogIfNeeded(payLoad);
  }

  onClose() {
    this.closed.emit();
    this.closeDialogIfNeeded();
  }

  onSelectedTabChange(evt: MatTabChangeEvent) {
    this.tabIndex = evt.index;
  }

  onScanBarcode() {
    this.inAppService.startBarCodeScanner();
  }

  onSubmit(event: Event) {
    this.searchInput.nativeElement.blur();
    event.preventDefault();
  }

  resetValueIfBarcodeSearch() {
    if (this.isBarcodeSearch) {
      this.form.setValue({ search: '' }, { emitEvent: false });
      this.isBarcodeSearch = false;
    }
  }

  private minDigitsFilter() {
    return (value: string) => value.length >= this.minDigits;
  }

  private excludeBarcodeSearches() {
    return () => !this.isBarcodeSearch;
  }

  private updateProgressVisibility() {
    this.progressVisible = this.isFetchingMeals || this.isFetchingResult || this.isFetchingFavorites;
  }

  private updateProgressOnValueChanges(searchChanged: Observable<string>) {
    searchChanged
      .pipe(
        tap((value: string) => (this.progressVisible = this.tabIndex > 0 || value.length >= this.minDigits))
      )
      .subscribe();
  }

  private fetchResultsOnValueChanges(searchChanged: Observable<string>) {
    searchChanged
      .pipe(
        tap((value: string) => {
          if (!this.fetchAll && value.length < this.minDigits) {
            this.allResults = [];
          }
        }),
        filter((value: string) => this.minDigitsFilter()(value) || this.fetchAll),
        tap(() => (this.isFetchingResult = true)),
        switchMap((value: string) =>
          this.fetchResults(value).pipe(
            map(response => ({ response, error: undefined })),
            catchError(error => of({ error, response: undefined }))
          )
        ),
        tap(() => {
          this.isFetchingResult = false;
          this.updateProgressVisibility();
        }),
        filter(({ error }) => error === undefined),
        tap(({ response }) => {
          this.allResults = response.content;
        })
      )
      .subscribe();
  }

  private fetchMyMealsOnValueChanges(searchChanged: Observable<string>) {
    if (this.isFoodSearch) {
      searchChanged
        .pipe(
          filter(this.excludeBarcodeSearches()),
          tap(() => (this.isFetchingMeals = true)),
          switchMap((value: string) =>
            this.fetchMyMeals(value).pipe(
              map(response => ({ response, error: undefined })),
              catchError(error => of({ response: undefined, error }))
            )
          ),
          tap(() => {
            this.isFetchingMeals = false;
            this.updateProgressVisibility();
          }),
          filter(({ error }) => error === undefined),
          tap(
            ({ response }: { response: IBitfApiResponse<MyMeal[]>; error: any }) =>
              (this.myMeals = response.content)
          )
        )
        .subscribe();
    }
  }

  private fetchFavoritesOnValueChanges(searchChanged: Observable<string>) {
    if (this.isFoodSearch) {
      searchChanged
        .pipe(
          filter(this.excludeBarcodeSearches()),
          tap(() => (this.isFetchingFavorites = true)),
          switchMap((value: string) =>
            this.usersService
              .get<Favorite>({
                id: this.storeService.store.user.id,
                relation: 'favoriteFoods',
                embed: ['product'],
                search: value,
              })
              .pipe(
                map(response => ({ response, error: undefined })),
                catchError(error => of({ response: undefined, error }))
              )
          ),
          tap(() => {
            this.isFetchingFavorites = false;
            this.updateProgressVisibility();
          }),
          filter(({ error }) => error === undefined),
          tap(({ response }: { response: IBitfApiResponse<Favorite[]> }) => {
            this.favorites = response.content.map((favorite: Favorite) => favorite.product);
          })
        )
        .subscribe();
    }
  }

  private listenToBarcodeEvents() {
    this.storeService
      .selectStore(EStoreActions.BAR_CODE_RECEIVED)
      .pipe(
        takeUntil(this.unsubscribe$),
        tap(() => {
          this.isBarcodeSearch = true;
          this.form.setValue({
            search: this.storeService.store.barcode,
          });
        })
      )
      .subscribe();
  }

  private fetchResults(searchString: string) {
    const embed = [];
    if (this.searchService instanceof FoodProductService) {
      embed.push('manufacter');
    }
    const request: IBitfApiRequest = {
      embed,
    };
    if (this.isBarcodeSearch) {
      request.filter = `barcode eq '${searchString}'`;
    } else {
      request.search = searchString;
      request.page = 1;
      request.size = 40;
    }
    return this.searchService
      .get<SearchFoodResult | PhysicalActivity>(request)
      .pipe(takeUntil(this.fetchAbort$));
  }

  private fetchMyMeals(value: string) {
    return this.usersService
      .get<Favorite>({
        id: this.storeService.store.user.id,
        // tslint:disable-next-line:quotemark
        filter: `contains(tolower(label),'${value.toLowerCase().replace(/'/g, "''")}')`,
        relation: 'myMeals',
      })
      .pipe(takeUntil(this.fetchAbort$));
  }

  private closeDialogIfNeeded(data?: ISearchResult) {
    if (!this.usedAsComponent) {
      if (this.dialogRef) {
        this.dialogRef.close({
          status: EBitfCloseEventStatus.OK,
          data,
        } as IBitfCloseEvent<ISearchResult>);
      } else {
        this.bitfMatSidenavService.close({
          status: EBitfCloseEventStatus.CLOSE,
          data,
        });
      }
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
