import { Component } from '@angular/core';
import { CommonLayout } from '@common/shared/components/layout/common-layout.component';

@Component({
  selector: 'aboca-mobile-layout',
  templateUrl: './mobile-layout.component.html',
  styleUrls: ['./mobile-layout.component.scss'],
})
export class MobileLayoutComponent extends CommonLayout {
  updateLayout() {
    const data = this.route.snapshot.firstChild.data;
    this.showHeader = data.showHeader;
    this.showNavbar = data.showNavbar;
  }
}
