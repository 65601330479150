import { IMenuItem } from '@interfaces';
import { EUserDefaultRoutes } from '@enums';
import { bitfToTranslate } from '@bitf/utils/bitf-translate.utils';

export const NAVBAR_ELEMENTS: IMenuItem[] = [
  toMenuItem({ label: bitfToTranslate('NAVBAR.TODAY'), icon: 'today', route: EUserDefaultRoutes.DASHBOARD }),
  toMenuItem({
    label: bitfToTranslate('NAVBAR.PLANNING'),
    icon: 'calendar_month',
    route: EUserDefaultRoutes.PLANNING,
  }),
  toMenuItem({
    label: bitfToTranslate('NAVBAR.FAVORITES'),
    icon: 'favorite',
    route: EUserDefaultRoutes.FAVORITES,
  }),
  toMenuItem({
    label: bitfToTranslate('NAVBAR.PROGRESS'),
    icon: 'bar_chart',
    route: EUserDefaultRoutes.MY_PROGRESS,
  }),
  toMenuItem({
    label: bitfToTranslate('NAVBAR.INFO'),
    icon: 'info',
    route: EUserDefaultRoutes.INFO,
  }),
];

function toMenuItem(data: { label: string; icon: string; route: EUserDefaultRoutes }): IMenuItem {
  return {
    label: data.label,
    icon: data.icon,
    route: data.route,
    url: '/' + data.route,
  };
}
