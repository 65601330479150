import { SuperModel } from './super-model.model';
import { bitfFormatDateISO } from '@bitf/utils/bitf-dates.utils';

export class UserData extends SuperModel {
  birthDate: Date;
  userId: string;
  gender: EGender;
  height: number;
  weight: number;
  waistCircumference: number;
  isSmoker: boolean;
  isPregnant: boolean;
  isBreastFeeding: boolean;
  isPostMenopausal: boolean;
  hasCardiacDiseases: boolean;
  hasOtherDiseases: boolean;
  hasCardiacPressureDiseases: boolean;
  hasFamilyDiseases: boolean;
  hasBloodPressureExcess: boolean;
  hasCholesterolLdlExcess: boolean;
  hasCholesterolHdlExcess: boolean;
  hasGlycemiaExcess: boolean;
  needId: ENeeds;
  diaryUse: EDiaryUse;
  wizardResult: number;
  imc: number;
  eatingHabitId: EEatingHabits;

  constructor(data?: any) {
    super(data);
    this.birthDate = this.birthDate != null ? new Date(this.birthDate) : this.birthDate;
  }

  couldBePregnant() {
    const yearsOld = new Date().getFullYear() - this.birthDate.getFullYear();
    return this.gender === EGender.FEMALE && yearsOld < 55;
  }

  get serialised() {
    return {
      birthDate: this.birthDate && bitfFormatDateISO(this.birthDate),
      userId: this.userId,
      gender: this.gender,
      height: this.height,
      weight: this.weight,
      waistCircumference: this.waistCircumference,
      isSmoker: this.isSmoker,
      isPregnant: this.isPregnant,
      isBreastFeeding: this.isBreastFeeding,
      isPostMenopausal: this.isPostMenopausal,
      hasCardiacDiseases: this.hasCardiacDiseases,
      hasOtherDiseases: this.hasOtherDiseases,
      hasCardiacPressureDiseases: this.hasCardiacPressureDiseases,
      hasFamilyDiseases: this.hasFamilyDiseases,
      hasBloodPressureExcess: this.hasBloodPressureExcess,
      hasCholesterolLdlExcess: this.hasCholesterolLdlExcess,
      hasCholesterolHdlExcess: this.hasCholesterolHdlExcess,
      hasGlycemiaExcess: this.hasGlycemiaExcess,
      needId: this.needId,
      diaryUse: this.diaryUse,
      wizardResult: this.wizardResult,
      imc: this.imc,
      eatingHabitId: this.eatingHabitId,
    };
  }
}

export enum EGender {
  MALE = 'Male',
  FEMALE = 'Female',
  NONE = 'None',
}

export enum ENeeds {
  NOT_SET = 1,
  IRRITABLE_BOWEL_SYNDROME = 2,
  CONSTIPATION = 3,
  ACIDITY_REFLUX = 4,
  OVER_WEIGHT = 5,
}

export enum EDiaryUse {
  NOT_SET = 'NotSet',
  DIARY = 'Diary',
  NECESSITIES_MENU = 'NecessitiesMenu',
  ANNOTATIONS = 'Annotations',
}

export enum EEatingHabits {
  NORMAL = 1,
  VEGETARIAN = 2,
  VEGAN = 3,
}
