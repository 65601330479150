import { MyMealEditComponent } from './favorites/my-meals/my-meal-edit/my-meal-edit.component';
// tslint:disable-next-line:max-line-length
import { CopyToDiaryDialogComponent } from './favorites/my-meals/copy-to-diary-dialog/copy-to-diary-dialog.component';
import { FavoritesHeaderComponent } from './favorites-header/favorites-header.component';
import { FavoriteSearchComponent } from './favorite-search/favorite-search.component';

export const FAVORITE_COMPONENTS = [
  MyMealEditComponent,
  CopyToDiaryDialogComponent,
  FavoritesHeaderComponent,
  FavoriteSearchComponent,
];
export const FAVORITE_ENTRY_COMPONENTS = [
  MyMealEditComponent,
  CopyToDiaryDialogComponent,
  FavoritesHeaderComponent,
  FavoriteSearchComponent,
];
